import { useState, useRef } from "react";
import RecordRTC from "recordrtc";

const useAudioRecorderV3 = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [error, setError] = useState(null);
  const [duration, setDuration] = useState(null);
  const recorderRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const handleStartRecording = () => {
    setError(null);
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          channels: 1,
          autoGainControl: false,
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 48000,
        },
      })
      .then((stream) => {
        const recorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/wav",
          desiredSampRate: 16000,
          recorderType: RecordRTC.StereoAudioRecorder,
        });
        recorderRef.current = recorder;
        recorderRef.current.stream = stream;
        recorder.startRecording();
        setIsRecording(true);
        setIsStopped(false);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        setError("Error accessing microphone: " + error);
      });
  };
  const handleStopRecording = () => {
    const recorder = recorderRef.current;
    if (recorder && isRecording) {
      setIsRecording(false);
      setIsStopped(true);
      setIsProcessing(true);
      recorder.stopRecording(() => {
        recorder.stream.stop();
        const audioBlob = recorder.getBlob();
        setAudioBlob(audioBlob);
        extractDuration(audioBlob);
        setIsRecording(false);
        setIsStopped(true);
        setIsProcessing(false);
      });
    }
  };
  const handlePauseRecording = () => {
    if (recorderRef.current && isRecording && !isPaused) {
      recorderRef.current.pauseRecording();
      setIsRecording(false);
      setIsPaused(true);
    }
  };
  const handleResumeRecording = () => {
    if (recorderRef.current && isPaused) {
      recorderRef.current.resumeRecording();
      setIsRecording(true);
      setIsPaused(false);
    }
  };
  const extractDuration = (audioBlob) => {
    if (audioBlob) {
      const audioElement = new Audio(URL.createObjectURL(audioBlob));
      audioElement.addEventListener("loadedmetadata", () => {
        setDuration(audioElement.duration);
      });
    }
  };
  return {
    isRecording: isRecording,
    isStopped: isStopped,
    isPaused: isPaused,
    audioBlob: audioBlob,
    recordingError: error,
    duration: duration,
    isProcessing: isProcessing,
    setAudioBlob: setAudioBlob,
    startRecording: handleStartRecording,
    stopRecording: handleStopRecording,
    pauseRecording: handlePauseRecording,
    resumeRecording: handleResumeRecording,
  };
};
export default useAudioRecorderV3;
