import React from "react";
import "./PermissionsStatusLoader.css";
import { Backdrop, CircularProgress } from "@mui/material";

function PermissionsStatusLoader({ open }) {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default PermissionsStatusLoader;
