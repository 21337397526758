import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useTranslationSetup from "../../utils/useTranslationSetup";
import CustomSelectorChip from "../../components/CustomSelectorChip/CustomSelectorChip";
import assessLangCloud1 from "../../assets/assess-lang-cloud1.svg";
import assessLangCloud2 from "../../assets/assess-lang-cloud2.svg";
import assessLangCloud3 from "../../assets/assess-lang-cloud3.svg";
import assessLangCloud4 from "../../assets/assess-lang-cloud4.svg";
import assessLangCloud5 from "../../assets/assess-lang-cloud5.svg";
import assessLangCloud6 from "../../assets/assess-lang-cloud6.svg";
import assessLangCloud7 from "../../assets/assess-lang-cloud7.svg";
import assessLangCloud8 from "../../assets/assess-lang-cloud8.svg";
import assessLangCloud9 from "../../assets/assess-lang-cloud9.svg";
import "./SelectAssessmentLangScreen.css";
import useParamValues from "../../utils/useParamValues";

function SelectAssessmentLangScreen({
  setShowAssessmentLangScreen,
  setShowSplashLoadingScreen,
  assessmentLang,
  setAssessmentLang,
}) {
  const { organisation } = useParamValues();
  const { t } = useTranslationSetup(); // Make sure t is in scope here

  const guHiOrgIds = new Set(["pratham_demo_1"]); // org_ids for Gujarati and Hindi
  const hiOrgIds = new Set(["bcg_rajasthan_1"]); // org_ids for Hindi
  const enHiOrgIds = new Set([
    "bihar_demo_1",
    "bihar_pilot_1",
    "bihar_pilot_2",
    "generic_demo",
    "demo",
  ]); // org_ids for English and Hindi

  const assessmentLangGuHiOptions = [
    {
      label: t("gujarati"),
      abbreviation: "(GU)",
      value: "gu",
    },
    {
      label: t("hindi"),
      abbreviation: "(हिं)",
      value: "hi",
    },
  ];

  const assessmentLangEnHiOptions = [
    {
      label: t("english"),
      abbreviation: "(EN)",
      value: "en",
    },
    {
      label: t("hindi"),
      abbreviation: "(हिं)",
      value: "hi",
    },
  ];

  const assessmentLangHiOptions = [
    {
      label: t("hindi"),
      abbreviation: "(हिं)",
      value: "hi",
    },
  ];

  function getAssessmentLangOptions(orgId) {
    if (guHiOrgIds.has(orgId)) {
      return assessmentLangGuHiOptions;
    }
    if (enHiOrgIds.has(orgId)) {
      return assessmentLangEnHiOptions;
    }
    if (hiOrgIds.has(orgId)) {
      return assessmentLangHiOptions;
    }
    return assessmentLangEnHiOptions;
  }

  const handleNavigateToSplashLoadingScreen = () => {
    setShowAssessmentLangScreen(false);
    setShowSplashLoadingScreen(true);
  };

  return (
    <>
      <div
        style={{
          background: "rgba(244, 244, 244, 1)",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: "10%",
        }}
      >
        <div className="select-assessment-lang-heading-container">
          <div className="heading">{t("choose_language")}</div>
          <div className="heading-content">
            {t("select_language_you_want_assessment_with")}
          </div>
        </div>
        <div className="custom-selector-chip">
          <CustomSelectorChip
            setValue={setAssessmentLang}
            options={getAssessmentLangOptions(organisation)}
          />
        </div>
        <div className="sticky-next-button">
          <div className="select-assessment-lang-next-button">
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              style={{
                height: "46px",
                width: "70%",
                textTransform: "none",
                borderRadius: "10px",
                color: "#fff",
                fontWeight: "500",
                fontSize: "16px",
                fontFamily: "Poppins",
                background: assessmentLang !== null ? "#E47714" : undefined,
              }}
              disabled={assessmentLang === null}
              disableElevation
              onClick={handleNavigateToSplashLoadingScreen}
            >
              {t("next")}
            </Button>
          </div>
        </div>
      </div>
      <img src={assessLangCloud1} alt="assess-lang-cloud1-img" className="assess-lang-cloud1" />
      <img src={assessLangCloud2} alt="assess-lang-cloud2-img" className="assess-lang-cloud2" />
      <img src={assessLangCloud3} alt="assess-lang-cloud3-img" className="assess-lang-cloud3" />
      <img src={assessLangCloud4} alt="assess-lang-cloud4-img" className="assess-lang-cloud4" />
      <img src={assessLangCloud5} alt="assess-lang-cloud5-img" className="assess-lang-cloud5" />
      <img src={assessLangCloud6} alt="assess-lang-cloud6-img" className="assess-lang-cloud6" />
      <img src={assessLangCloud7} alt="assess-lang-cloud7-img" className="assess-lang-cloud7" />
      <img src={assessLangCloud8} alt="assess-lang-cloud8-img" className="assess-lang-cloud8" />
      <img src={assessLangCloud9} alt="assess-lang-cloud9-img" className="assess-lang-cloud9" />
    </>
  );
}

export default SelectAssessmentLangScreen;
