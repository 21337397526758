import React, { useState } from "react";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import VopaApp from "./VopaApp";

function DemoApp({ organisation }) {
  const [splash, setSplash] = useState(true);
  return (
    <div>
      {splash ? (
        <SplashScreen organisation={organisation} setSplash={setSplash} />
      ) : (
        <VopaApp organisation={organisation} setSplash={setSplash} />
      )}
    </div>
  );
}

export default DemoApp;
