import React from "react";
import "./ResultCard.css";

function ResultCard({
  heading,
  headingColor,
  bgColor,
  value,
  image,
  handleQuickSightCardOnClick = () => {},
}) {
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      handleQuickSightCardOnClick(heading);
    }
  };

  return (
    <div
      className="result-card"
      style={{ borderColor: headingColor, backgroundColor: bgColor }}
      onClick={() => handleQuickSightCardOnClick(heading)}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={0}
      aria-label={`Card for ${heading}`}
    >
      <div className="result-heading" style={{ color: headingColor }}>
        {heading}
      </div>
      <div className="result-value-div">
        <img src={image} alt="" />
        <div className="result-value">{value}</div>
      </div>
    </div>
  );
}

export default ResultCard;
