import React, { useEffect, useState } from "react";
import "./DetailedResultCard.css";
import dropDown from "../../assets/drop-down.svg";
import useTranslationSetup from "../../utils/useTranslationSetup";

function DetailedResultCard({
  index,
  heading,
  subHeading,
  bgColor,
  icon,
  innerBorderColor,
  words,
  scrollCardIndex,
}) {
  const { t } = useTranslationSetup();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(index === scrollCardIndex);
  }, [index, scrollCardIndex]);

  const dropdown = () => {
    setOpen(!open);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      dropdown();
    }
  };

  return (
    <div
      className="detailed-result-item-wrapper"
      style={{ backgroundColor: bgColor }}
    >
      {heading === t("incorrect_words") && (
        <div className="detailed-result-item-attention">
          {t("needs_attention")}
        </div>
      )}
      <div className="detailed-result-item">
        <div
          className="detailed-result-item-icon"
          style={{ borderColor: innerBorderColor }}
        >
          <img src={icon} alt={heading} />
        </div>
        <div className="detailed-result-item-header">
          <div className="detailed-result-item-heading">{heading}</div>
          <div className="detailed-result-item-subheading">{subHeading}</div>
        </div>
        <div
          className="detailed-result-item-action"
          onClick={dropdown}
          onKeyDown={handleKeyPress}
          role="button" 
          tabIndex={0} 
          aria-label={`Expand ${heading}`} 
        >
          <div className="detailed-result-item-btn">
            <img src={dropDown} alt="drop-down" />
          </div>
          <div className="detailed-result-item-subheading">
            {t("see_all_words")}
          </div>
        </div>
      </div>
      {open && (
        <div className="detailed-result-item-opendropdown">
          {words.map((word) => (
            <div key={word} className="detailed-result-item-opendropdown-word">
              {word}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DetailedResultCard;
