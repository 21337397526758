import React, { useState, useEffect, useCallback } from "react";
import { CircularProgress } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Header from "./Header";
import createAssessmentApi from "./fetchApi/createAssessmentApi";
import CustomErrorDialogBox from "./components/CustomErrorDialogBox/CustomErrorDialogBox";
import useTranslationSetup from "./utils/useTranslationSetup";
import { SchoolIdName, UrlConstants } from "./utils/CommonUtils/constants";
import girlHoldingPhoneHi from "./assets/student_holding_phone_hi_letter.svg";
import girlHoldingPhoneEn from "./assets/student_holding_phone_en_letter.svg";
import sendFeedback from "./fetchApi/sendFeedback";
import fetchAssessmentsList from "./fetchApi/fetchAssessmentsList";

function EnterOrfApplicationLoadingScreen({
  setParaData,
  studentName,
  setShowVopaInputFieldsScreen,
  setShowSplashLoadingScreen,
  selectedLaguage,
  setAssessmentLang,
  organisation,
  requestBody,
  setFeedbackResponse,
  feedbackData,
  assessmentLang,
  setAssessmentListData,
  mode,
  studentId,
}) {
  const [assessmentCreatingStatus, setAssessmentCreatingStatus] =
    useState(null);
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const { t } = useTranslationSetup();
  const getFirstNameAndTruncate = (name) => {
    const firstName = name.split(" ")[0];
    if (firstName.length > 20) {
      return `${firstName.substring(0, 20)}...`;
    }
    return firstName;
  };

  const handleAssessmentError = (errorType = "ERROR") => {
    setOpenDialogForError(true);
    setAssessmentCreatingStatus(errorType);
  };
  const sendFeedbackData = useCallback(
    async (feedbackDataToSend, feedbackUrl) => {
      try {
        const response = await sendFeedback(feedbackDataToSend, feedbackUrl);
        if (response.status === "ERROR") {
          handleAssessmentError(response.status);
        } else {
          setFeedbackResponse(response);
        }
      } catch (error) {
        handleAssessmentError("ERR_INTERNET_DISCONNECTED");
      }
    },
    [handleAssessmentError, setFeedbackResponse]
  );

  const handleAssessmentSuccess = useCallback(
    (response) => {
      setParaData(response);
      // if (organisation === "pratham_demo_1") {
      //   const feedbackUrl = `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}/${response.id}/feedback`;
      //   sendFeedbackData(feedbackUrl);
      // }
      let feedbackDataToSend;
      if (organisation === "pratham_demo_1") {
        feedbackDataToSend = feedbackData;
      } else if (
        organisation === "bihar_demo_1" ||
        organisation === "bihar_pilot_1" ||
        organisation === "bihar_pilot_2"
      ) {
        feedbackDataToSend = {
          feedback: { gender: feedbackData.feedback.gender },
        };
      } else if (organisation === "delhi_mcd_1") {
        const school = SchoolIdName.find(
          (sch) => sch.schoolId === requestBody.school_id
        );
        feedbackDataToSend = {
          feedback: {
            teacher_name: feedbackData.feedback.instructor_name,
            gender: feedbackData.feedback.gender,
            student_name: feedbackData.feedback.student_name,
            school_name: school.schoolName,
            grade_section: feedbackData.feedback.grade_section,
            school_shift: feedbackData.feedback.school_shift,
          },
        };
      } else if (organisation === "bcg_rajasthan_1") {
        feedbackDataToSend = {
          feedback: {
            gender: feedbackData.feedback.gender,
            grade_section: feedbackData.feedback.grade_section,
          },
        };
      }

      if (feedbackDataToSend) {
        const feedbackUrl = `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}/${response.id}/feedback`;
        sendFeedbackData(feedbackDataToSend, feedbackUrl);
      }
    },
    [organisation, requestBody, feedbackData, setParaData, sendFeedbackData]
  );
  useEffect(() => {
    setTimeout(() => {
      if (mode === "assessment") {
        (async () => {
          try {
            const response = await createAssessmentApi(
              `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}`,
              studentName,
              selectedLaguage,
              requestBody
            );

            if (response.status === "ERROR") {
              handleAssessmentError(response.status);
            } else {
              handleAssessmentSuccess(response);
            }
          } catch (error) {
            handleAssessmentError("ERR_INTERNET_DISCONNECTED");
          }
        })();
      } else if (mode === "performance") {
        fetchAssessmentsList(
          `${
            UrlConstants.BASE_URL +
            UrlConstants.BASE_PATH_URL +
            UrlConstants.ASSESSMENT
          }?student_id=${studentId}`
        )
          .then((response) => {
            if (response.status === "ERROR") {
              setOpenDialogForError(true);
              setAssessmentCreatingStatus(response.status);
            } else {
              setAssessmentListData(response);
            }
          })
          .catch(() => {
            setOpenDialogForError(true);
            setAssessmentCreatingStatus("ERR_INTERNET_DISCONNECTED");
          });
      }
    }, 3000);
  }, [mode]);

  return (
    <>
      {(assessmentCreatingStatus === "ERROR" ||
        assessmentCreatingStatus === "ERR_INTERNET_DISCONNECTED") && (
        <CustomErrorDialogBox
          header={t("something_went_wrong")}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: "#D95959",
                borderRadius: "10px",
                marginTop: "16px",
                width: "80px",
                height: "80px",
              }}
            />
          }
          secondaryButtonText={t("try_again")}
          isDialogOpen={openDialogForError}
          showPrimaryButton={false}
          showSecondaryButton
          onClickSecondaryButton={() => {
            setOpenDialogForError(false);
            setShowSplashLoadingScreen(false);
            setShowVopaInputFieldsScreen(true);
            setAssessmentLang(null);
          }}
        />
      )}
      <div>
        <Header
          heading={t("orf_assess")}
          background="linear-gradient(134.56deg, #E47714 59.55%, rgba(228, 119, 20, 0.4) 152.08%)"
          hideArrow
        />
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            marginTop: "8px",
            textAlign: "center",
            color: "#373737",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              wordBreak: "break-word",
              overfloWrap: "break-word",
              padding: "0 8px",
            }}
          >
            {getFirstNameAndTruncate(studentName)}
          </div>
          {mode === "assessment" && (
            <div
              style={{
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "400",
                letterSpacing: "-1.9%",
                lineHeight: "24px",
                padding: "4px 0 0",
              }}
            >
              {`${assessmentLang.label} ${t("assessment")}`}
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-65%, -50%)",
          }}
        >
          <picture>
            <img
              style={{
                height: "190px",
              }}
              src={
                assessmentLang.value === "hi"
                  ? girlHoldingPhoneHi
                  : girlHoldingPhoneEn
              }
              alt="welcome-logo"
            />
          </picture>
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "8%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              fontFamily: "Poppins",
              fontWeight: "400",
              color: "#000000",
            }}
          >
            {t("loading")}
            <div>
              <CircularProgress size="12px" background="black" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnterOrfApplicationLoadingScreen;
