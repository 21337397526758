import { UrlConstants } from "../utils/CommonUtils/constants";

const uploadAudio = (url, password, username="BCG Rajasthan") => {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization:`Bearer ${UrlConstants.TOKEN}`,
    },
  };

  return fetch(url, options).then((response) => {
    if (response.status === 401) {
        return {
          status: "UNAUTHENTICATED",
          data: "Incorrect password"
        };
      }
    if (response.ok) {
      return response.json();
    } 
      return {
        status: "ERROR",
        data: "Error occured"
      };
  }).catch(()=>{
    return {
        status: "ERROR",
        data: "Error occured"
    };
  })
};

export default uploadAudio;
