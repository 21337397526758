import React from "react";
import Lottie from "react-lottie-player";
import "./StudentImgWithHandMobileGif.css";
import handMobileJson from "../../../assets/LottieFiles/child_handling_mobile.json";

function StudentImgWithHandMobileGif() {
  return (
    <div className="parent-container">
      <div className="child-container">
        {/* <div
          style={{
            textAlign: "center",
          }}
        >
          <img
            src={studentDistanceImg}
            alt="student_distance_img"
            className="student-distance-img"
          />
        </div> */}
        <div className="handle-mobile-gif">
          <Lottie
            loop
            animationData={handMobileJson}
            
            play
            style={{ width: "137px", height: "137px", margin: "43px 0 43px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default StudentImgWithHandMobileGif;
