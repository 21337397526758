import React, { useState, useEffect } from "react";

import CustomAudioRecorder from "./components/CustomAudioRecorder/CustomAudioRecorder";
import useTranslationSetup from "./utils/useTranslationSetup.js";
import AudioSizeShortError from "./components/AudioSizeShortError/AudioSizeShortError.js";
import AssessmentSaveAudioConfirmationDrawer from "./components/AssessmentSaveAudioConfirmationDrawer/AssessmentSaveAudioConfirmationDrawer.js";
import useAudioRecorderV3 from "./utils/useAudioRecorderV3.js";

const AudioRecorder = ({
  hideSlider,
  setHideSlider,
  setHideArrow,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  showOrfResultScreen,
  setShowOrfResultScreen,
  processedData,
  setProcessedData,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  studentName,
  setStudentName,
  orfResultStatus,
  setOrfResultStatus,
  selectedLaguage,
  setSelectedLaguage,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
  setProfession,
  setGender,
  scrollParagraphToTop,
  setSection,
  setSchoolShift,
}) => {
  const {
    isRecording,
    isStopped,
    isProcessing,
    isPaused,
    audioBlob,
    recordingError,
    duration,
    setAudioBlob,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
  } = useAudioRecorderV3();
  const [openDialogForError, setOpenDialogForError] = useState(false);
  const [
    openAssessmentSaveAudioConfirmationDrawer,
    setAssessmentSaveAudioConfirmationDrawer,
  ] = useState(false);
  const [showSaveResultDialog, setShowSaveResultDialog] = useState(false);
  const { t } = useTranslationSetup();

  if (isRecording) {
    setHideArrow(true);
  } else {
    setHideArrow(false);
    setAudioBlobForAudioPlayer(audioBlob);
  }

  useEffect(() => {
    if (duration === undefined || (duration !== null && duration <= 5)) {
      setOpenDialogForError(true);
    }
  }, [duration]);

  return (
    <>
      <AudioSizeShortError
        isDialogOpen={openDialogForError}
        setDialogOpen={setOpenDialogForError}
        warningHeading={t("audio_size_small_heading")}
        warningMessage={t("audio_size_small_text")}
        onClickRecordAgain={() => {
          setOpenDialogForError(false);
          setHideSlider(false);
          setAudioBlobForAudioPlayer(null);
          setAudioBlob(null);
        }}
        t={t}
      />

      <AssessmentSaveAudioConfirmationDrawer
        openDrawer={openAssessmentSaveAudioConfirmationDrawer}
        setOpenDrawer={setAssessmentSaveAudioConfirmationDrawer}
        audioBlob={audioBlobForAudioPlayer}
        setAudioBlob={setAudioBlobForAudioPlayer}
        onClickSaveButton={() => {
          setAudioUploading(true);
          setShowSaveResultDialog(true);
          setSaveClicked(true);
          setAssessmentSaveAudioConfirmationDrawer(false);
        }}
        onClickRetakeButton={() => {
          setAssessmentSaveAudioConfirmationDrawer(false);
          setOpenDialogForError(false);
          setHideSlider(false);
          setAudioBlobForAudioPlayer(null);
          setAudioBlob(null);
          setAudioUploading(false);
          setShowSaveResultDialog(false);
          setSaveClicked(false);
        }}
      />

      <CustomAudioRecorder
        isRecording={isRecording}
        isStopped={isStopped}
        isPaused={isPaused}
        audioBlob={audioBlob}
        recordingError={recordingError}
        setAudioBlob={setAudioBlob}
        startRecording={startRecording}
        stopRecording={stopRecording}
        pauseRecording={pauseRecording}
        resumeRecording={resumeRecording}
        hideSlider={hideSlider}
        setHideSlider={setHideSlider}
        showResultLoadingScreen={showResultLoadingScreen}
        setShowResultLoadingScreen={setShowResultLoadingScreen}
        showOrfTestScreen={showOrfTestScreen}
        setShowOrfTestScreen={setShowOrfTestScreen}
        audioBlobForAudioPlayer={audioBlobForAudioPlayer}
        setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
        showOrfResultScreen={showOrfResultScreen}
        setShowOrfResultScreen={setShowOrfResultScreen}
        processedData={processedData}
        setProcessedData={setProcessedData}
        assessmentId={assessmentId}
        setAssessmentId={setAssessmentId}
        showHomeScreen={showHomeScreen}
        setShowHomeScreen={setShowHomeScreen}
        studentName={studentName}
        setStudentName={setStudentName}
        orfResultStatus={orfResultStatus}
        setOrfResultStatus={setOrfResultStatus}
        selectedLaguage={selectedLaguage}
        setSelectedLaguage={setSelectedLaguage}
        classNum={classNum}
        setClassNum={setClassNum}
        schoolUdise={schoolUdise}
        setSchoolUdise={setSchoolUdise}
        saveClicked={saveClicked}
        setSaveClicked={setSaveClicked}
        isAudioUploading={isAudioUploading}
        setAudioUploading={setAudioUploading}
        uploadAudioStatus={uploadAudioStatus}
        setUploadAudioStatus={setUploadAudioStatus}
        setProfession={setProfession}
        openAssessmentSaveAudioConfirmationDrawer={
          openAssessmentSaveAudioConfirmationDrawer
        }
        setAssessmentSaveAudioConfirmationDrawer={
          setAssessmentSaveAudioConfirmationDrawer
        }
        showSaveResultDialog={showSaveResultDialog}
        setShowSaveResultDialog={setShowSaveResultDialog}
        setGender={setGender}
        scrollParagraphToTop={scrollParagraphToTop}
        setSection={setSection}
        setSchoolShift={setSchoolShift}
        isProcessing={isProcessing}
      />
    </>
  );
};

export default AudioRecorder;
