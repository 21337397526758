import React, { useState } from "react";
import useParamValues from "../../utils/useParamValues";
import useTranslationSetup from "../../utils/useTranslationSetup";
import {
  getClassNumOptions,
  getClassSectionOptions,
  getGenderOptions,
  getGradeLabel,
  getNameLabel,
  getSchoolIdLabel,
  getTeacherLabel,
} from "../../utils/helper";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import "./VopaInputFieldsScreen.css";

const StudentSelector = ({
  data,
  setGender,
  setClassNum,
  setSchoolUdise,
  setStudentId,
  setSection,
  setTeacherId,
  setName,
}) => {
  const { t } = useTranslationSetup();
  const { organisation } = useParamValues();
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedGender, setSelectedGender] = useState("");

  const handleSchoolChange = (value) => {
    setSelectedSchoolId(value);
    setSelectedTeacherId("");
    setSelectedGrade("");
    setSelectedSection("");
    setSelectedStudentId("");
    setSelectedGender("");
    setClassNum("");
    setGender("");
    setSchoolUdise("");
    setStudentId("");
    setSection("");
    setTeacherId("");
    setName("");
  };

  const handleTeacherChange = (value) => {
    setSelectedTeacherId(value);
    setSelectedGrade("");
    setSelectedSection("");
    setSelectedStudentId("");
    setSelectedGender("");
    setClassNum("");
    setGender("");
    setSchoolUdise("");
    setStudentId("");
    setSection("");
    setTeacherId("");
    setName("");
  };

  const handleGradeChange = (value) => {
    setSelectedGrade(value);
    setSelectedSection("");
    setSelectedStudentId("");
    setSelectedGender("");
    setClassNum("");
    setGender("");
    setSchoolUdise("");
    setStudentId("");
    setSection("");
    setTeacherId("");
    setName("");
  };

  const handleSectionChange = (value) => {
    setSelectedSection(value);
    setSelectedStudentId("");
    setSelectedGender("");
    setClassNum("");
    setGender("");
    setSchoolUdise("");
    setStudentId("");
    setSection("");
    setTeacherId("");
    setName("");
  };

  const handleStudentChange = (value) => {
    const studentId = value;
    setSelectedStudentId(studentId);

    const selectedSchool = data.schools.find(
      (school) => school.udise_id === selectedSchoolId
    );
    const allGrades = selectedSchool?.teachers.flatMap(
      (teacher) => teacher.grades
    );
    const selectedGradeSection = allGrades?.find(
      (grade) =>
        grade.grade === selectedGrade && grade.section === selectedSection
    );
    const selectedStudent = selectedGradeSection?.students.find(
      (student) => student.id === studentId
    );

    setSelectedGender(selectedStudent?.gender || "");
    setClassNum(selectedGrade);
    setGender(selectedStudent?.gender);
    setSchoolUdise(selectedSchoolId);
    setStudentId(studentId);
    setSection(selectedSection);
    setTeacherId(selectedTeacherId);
    setName(selectedStudent?.name || "");
  };

  const selectedSchool = data.schools.find(
    (school) => school.udise_id === selectedSchoolId
  );
  const selectedTeacher = selectedSchool?.teachers.find(
    (teacher) => teacher.teacher_id === selectedTeacherId
  );
  const allGrades = selectedSchool?.teachers.flatMap(
    (teacher) => teacher.grades
  );
  const uniqueGradesSections = allGrades?.filter(
    (grade, index, self) =>
      index ===
      self.findIndex(
        (g) => g.grade === grade.grade && g.section === grade.section
      )
  );
  const selectedGradeObj = uniqueGradesSections?.find(
    (grade) =>
      grade.grade === selectedGrade && grade.section === selectedSection
  );

  const schoolIdNameOptions = data.schools.map((school) => ({
    value: school?.udise_id,
    label: `${school?.name} ${school?.udise_id}`,
  }));

  const teacherIdNameOptions = selectedSchool?.teachers.map((teacher) => ({
    value: teacher.teacher_id,
    label: `${teacher.teacher_name} ${teacher.teacher_id}`,
  }));

  const gradeOptions = Array.from(
    new Set(selectedTeacher?.grades.map((item) => item.grade))
  ).map((grade) => ({
    value: grade,
    label: grade,
  }));

  const sectionOptions = selectedTeacher?.grades
    .filter((grade) => grade.grade === selectedGrade)
    .map((item) => ({
      value: item.section,
      label: item.section,
    }));

  const studentIdNameOptions = selectedGradeObj?.students.map((student) => ({
    value: student.id,
    label: `${student.name} ${student.id}`,
  }));

  return (
    <div className="student-selector-div">
      <div>
        <div className="input-label">
          {getSchoolIdLabel(organisation, t)}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedSchoolId}
          setSelectedValue={handleSchoolChange}
          options={schoolIdNameOptions}
        />
      </div>

      <div>
        <div className="input-label">
          {getTeacherLabel(organisation, t)}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedTeacherId}
          setSelectedValue={handleTeacherChange}
          options={teacherIdNameOptions || []}
          disabled={!selectedSchoolId}
        />
      </div>

      <div>
        <div className="input-label">
          {getGradeLabel(organisation, t)}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedGrade}
          setSelectedValue={handleGradeChange}
          options={gradeOptions || []}
          disabled={!selectedTeacherId}
        />
      </div>

      <div>
        <div className="input-label">
          {t("section")}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedSection}
          setSelectedValue={handleSectionChange}
          options={sectionOptions || []}
          disabled={!selectedGrade}
        />
      </div>

      <div>
        <div className="input-label">
          {getNameLabel(organisation, t)}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedStudentId}
          setSelectedValue={handleStudentChange}
          options={studentIdNameOptions || []}
          disabled={!selectedSection}
        />
      </div>

      <div>
        <div className="input-label">
          {t("gender")}
          <span className="asterisk">*</span>
        </div>
        <CustomDropDown
          selectedValue={selectedGender}
          options={getGenderOptions(organisation, t)}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default StudentSelector;
