import React, { useEffect, useState } from "react";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Button } from "@mui/material";
import "./CustomAudioRecorder.css";
import CustomActionButton from "../CustomActionButton/CustomActionButton";
import SaveResultDialogBox from "../SaveResultDialogBox/SaveResultDialogBox";
import DeleteAudioDialog from "../DeleteAudioDialog/DeleteAudioDialog";
import uploadAudio from "../../fetchApi/uploadAudio";
import useTranslationSetup from "../../utils/useTranslationSetup";
import { UrlConstants } from "../../utils/CommonUtils/constants";
import CustomBackdrop from "../CustomBackdrop/CustomBackdrop";
import formatTime from "../../utils/formatTime";

const CustomAudioRecorder = ({
  isRecording,
  isStopped,
  isPaused,
  audioBlob,
  setAudioBlob,
  startRecording,
  stopRecording,
  resumeRecording,
  hideSlider,
  setHideSlider,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  showOrfResultScreen,
  setShowOrfResultScreen,
  processedData,
  setProcessedData,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  studentName,
  setStudentName,
  orfResultStatus,
  setOrfResultStatus,
  selectedLaguage,
  setSelectedLaguage,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
  setAssessmentSaveAudioConfirmationDrawer,
  showSaveResultDialog,
  setShowSaveResultDialog,
  setProfession,
  setGender,
  scrollParagraphToTop,
  setSection,
  setSchoolShift,
  isProcessing,
}) => {
  // const [showSaveResultDialog, setShowSaveResultDialog] = useState(false);
  const [showDeleteAudioDialog, setShowDeleteAudioDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const { t } = useTranslationSetup();
  const [time, setTime] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  let currInterval;

  const handleStartRecording = () => {
    scrollParagraphToTop();
    setOpenBackdrop(true);
    setTimeout(() => {
      if (!openBackdrop) {
        setHideSlider(true);
        startRecording();
      }
    }, 5500);
  };

  const handleStopRecording = () => {
    stopRecording();
  };

  useEffect(() => {
    if (isRecording) {
      currInterval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      clearInterval(currInterval);
      setTime(0);
    };
  }, [isRecording]);

  useEffect(() => {
    if (saveClicked === true) {
      // setTimeout(() => {
      uploadAudio(
        `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}${UrlConstants.ASSESSMENT}/${assessmentId}/upload?self_audio_validation=1`,
        audioBlobForAudioPlayer
      )
        .then((result) => {
          if (result.status !== null || result.status !== undefined) {
            setAudioUploading(false);
            setUploadAudioStatus(result.status);
          }
        })
        .catch(() => {
          setUploadAudioStatus("ERR_INTERNET_DISCONNECTED");
        });
      // }, 3000);
    }
  }, [saveClicked]);

  return (
    <>
      <div>
        <CustomBackdrop
          openBackdrop={openBackdrop}
          setOpenBackdrop={setOpenBackdrop}
          t={t}
        />
      </div>
      <div>
        <SaveResultDialogBox
          isDialogOpen={showSaveResultDialog}
          setDialogOpen={setShowSaveResultDialog}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          showOrfResultScreen={showOrfResultScreen}
          setShowOrfResultScreen={setShowOrfResultScreen}
          processedData={processedData}
          setProcessedData={setProcessedData}
          assessmentId={assessmentId}
          setAssessmentId={setAssessmentId}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showHomeScreen={showHomeScreen}
          setShowHomeScreen={setShowHomeScreen}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          studentName={studentName}
          setStudentName={setStudentName}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          showErrorDialog={showErrorDialog}
          setShowErrorDialog={setShowErrorDialog}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          selectedLaguage={selectedLaguage}
          setSelectedLaguage={setSelectedLaguage}
          classNum={classNum}
          setClassNum={setClassNum}
          schoolUdise={schoolUdise}
          setSchoolUdise={setSchoolUdise}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          setProfession={setProfession}
          setGender={setGender}
          setSection={setSection}
          setSchoolShift={setSchoolShift}
        />
        <DeleteAudioDialog
          isDialogOpen={showDeleteAudioDialog}
          setDialogOpen={setShowDeleteAudioDialog}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          audioBlob={audioBlob}
          setAudioBlob={setAudioBlob}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "16px",
          alignItems: "center",
          height: "60px",
        }}
      >
        {isStopped && audioBlob != null && (
          // hideSlider == true
          <div
            style={{
              width: "100px",
              textAlign: "center",
              // pointerEvents: hideSlider && "none"
            }}
            onClick={() => setShowDeleteAudioDialog(true)}
          >
            <CustomActionButton text={t("retake")}>
              <CloseOutlinedIcon />
            </CustomActionButton>
          </div>
        )}
        <div>
          <Box>
            {isRecording && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "2px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#E70000",
                    borderRadius: "50%",
                    marginRight: "8px",
                    animation: "flashing-recording-dot 1s infinite",
                  }}
                />
                <div
                  style={{
                    color: "#000",
                    fontSize: "20px",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "150%",
                    letterSpacing: "-0.38px",
                  }}
                >
                  {formatTime(time)}
                </div>
              </div>
            )}

            <div
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Button
                variant={
                  isRecording
                    ? "outlined"
                    : audioBlob !== null && isStopped
                      ? "outlined"
                      : "contained"
                }
                style={{
                  textTransform: "capitalize",
                  borderRadius: "12px",
                  color: isRecording
                    ? "#E47714"
                    : audioBlob !== null && isStopped
                      ? "#AEAEAE"
                      : "#fff",
                  background: isRecording
                    ? "#fff"
                    : audioBlob !== null && isStopped
                      ? ""
                      : "#E47714",
                  fontWeight: "600",
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  padding: "2px 18px",
                  letterSpacing: "-1.9%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border:
                    audioBlob !== null && isStopped
                      ? "1px solid #AEAEAE"
                      : "1px solid #E47714",
                }}
                className={
                  isStopped && audioBlob === null ? "pulsing-button" : ""
                }
                disabled={(audioBlob != null && isStopped) || isProcessing}
                disableElevation
                onClick={() => {
                  if (isRecording) {
                    handleStopRecording();
                  } else if (isPaused) {
                    resumeRecording();
                  } else {
                    handleStartRecording();
                  }
                }}
              >
                {isStopped && audioBlob == null && !isProcessing ? (
                  <>{t("start")}</>
                ) : isRecording ? (
                  <>{t("stop")}</>
                ) : isProcessing ? (
                  <>{t("loading")}</> 
                ) : (
                  <>{t("stop")}</>
                )}
              </Button>
            </div>
          </Box>
        </div>
        {isStopped && audioBlob != null && (
          <div
            style={{
              width: "100px",
              textAlign: "center",
            }}
            onClick={() => {
              setAssessmentSaveAudioConfirmationDrawer(true);
            }}
          >
            <CustomActionButton text={t("save")}>
              <DoneOutlinedIcon />
            </CustomActionButton>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomAudioRecorder;
