function CustomCard({ children }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          width: "90%",
          backgroundColor: "#FFFFFF",
          boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: "12px",
          // padding: " 0.5rem 0.5rem 0.5rem 1rem",
          position: "relative",
        }}
      >
        {children}
      </div>
    </div>
  );
}
export default CustomCard;
