import React from "react";
import "./CardsGroupComponent.css";

function CardsGroupComponent({ children }) {
  return (
    <div className="cards">
      <div className="card-1">{children}</div>
      <div className="card-2" />
      <div className="card-3" />
      <div className="card-4" />
    </div>
  );
}

export default CardsGroupComponent;
