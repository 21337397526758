import React from "react";

function CustomCircleCard({children}) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#FFFFFF",
          boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.25)",
          borderRadius: "100%",
          alignItems: "center",
          overflow: "hidden",
          height: "5.5rem",
          width: "5.5rem",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default CustomCircleCard;
