import VopaInputFieldsScreen from "./VopaInputFieldsScreen";

function VopaInputFieldsWrapper({
  organisation,
  name,
  setName,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  profession,
  setProfession,
  setShowVopaInputFieldsScreen,
  setShowAssessmentLangScreen,
  setStudentId,
  gender,
  setGender,
  age,
  setAge,
  instructorName,
  setInstructorName,
  phoneNumber,
  setPhoneNumber,
  readingLevel,
  setReadingLevel,
  setRequestBody,
  section,
  setSection,
  schoolShift,
  setSchoolShift,
  setShowLoadingScreen,
  setCurrentMode,
  setTeacherId,
  schoolData,
}) {
  let fieldsToShow = {
    showInstructorName: false,
    showPhoneNumber: false,
    showSchoolId: false,
    showGrade: false,
    showChildInfo: false,
    showProfession: false,
    showGender: false,
    showAge: false,
    showReadingLevel: false,
    showSection: false,
    showSchoolShift: false,
    showStudentSelector: false,
  };

  if (organisation === "pratham_demo_1") {
    fieldsToShow = {
      ...fieldsToShow,
      showInstructorName: true,
      showPhoneNumber: true,
      showGender: true,
      showAge: true,
      showReadingLevel: true,
      showChildInfo: true,
      showGrade: true,
    };
  } else if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" ||
    organisation === "bihar_pilot_2"
  ) {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showGender: true,
    };
  } else if (organisation === "demo") {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showProfession: true,
    };
  } else if (organisation === "delhi_mcd_1") {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showGrade: true,
      showGender: true,
      showInstructorName: true,
      showSection: true,
      showSchoolShift: true,
      showSchoolId: true,
    };
  } else if (organisation === "bcg_rajasthan_1") {
    fieldsToShow = {
      ...fieldsToShow,
      showStudentSelector: true,
    };
  } else {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showProfession: true,
    };
  }

  return (
    <VopaInputFieldsScreen
      name={name}
      setName={setName}
      classNum={classNum}
      setClassNum={setClassNum}
      schoolUdise={schoolUdise}
      setSchoolUdise={setSchoolUdise}
      profession={profession}
      setProfession={setProfession}
      setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
      setShowAssessmentLangScreen={setShowAssessmentLangScreen}
      setStudentId={setStudentId}
      gender={gender}
      setGender={setGender}
      age={age}
      setAge={setAge}
      instructorName={instructorName}
      setInstructorName={setInstructorName}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      readingLevel={readingLevel}
      setReadingLevel={setReadingLevel}
      setRequestBody={setRequestBody}
      section={section}
      setSection={setSection}
      schoolShift={schoolShift}
      setSchoolShift={setSchoolShift}
      fieldsToShow={fieldsToShow}
      setShowLoadingScreen={setShowLoadingScreen}
      setCurrentMode={setCurrentMode}
      setTeacherId={setTeacherId}
      schoolData={schoolData}
    />
  );
}

export default VopaInputFieldsWrapper;
