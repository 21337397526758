import { UrlConstants } from "../utils/CommonUtils/constants";

const uploadAudio = (url, audioBlobForAudioPlayer) => {
  const formData = new FormData();

  formData.append("file", audioBlobForAudioPlayer);

  const options = {
    method: "POST",
    body: formData,
    headers: {
      Authorization:`Bearer ${UrlConstants.TOKEN}`,
    },
  };

  return fetch(url, options).then((response) => {
    if (response.ok) {
      return {
        status: "COMPLETED",
      };
    } 
      return {
        status: "ERROR",
      };
    
  });
};

export default uploadAudio;
