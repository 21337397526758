import { UrlConstants } from "../utils/CommonUtils/constants";

const createAssessmentApi = (
  url,
  studentName,
  selectedLaguage,
  requestBody
) => {
  const options = {
    method: "POST",
    body: JSON.stringify(
      // {
      //   student_id: `STV${new Date().getTime()}`,
      //   teacher_id: "teacher_test_1",
      //   lang: "en",
      //   grade: 3,
      //   organisation_id: "test_org",
      // }
      { ...requestBody, self_audio_validation: 1 }
    ),
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET,PATCH",
      Authorization: `Bearer ${UrlConstants.TOKEN}`,
    },
  };

  return fetch(url, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    }
    return res.json();
  });
};

export default createAssessmentApi;
