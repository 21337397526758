import { OrganisationMapping } from "./CommonUtils/constants";

const biharIds = new Set(["bihar_pilot_1", "bihar_demo_1", "bihar_pilot_2"]);
const biharPrathamIds = new Set([...biharIds, "pratham_demo_1"]);
const biharPrathamDelhiIds = new Set([...biharPrathamIds, "delhi_mcd_1"]);
const biharPrathamRajIds = new Set([...biharPrathamIds, "bcg_rajasthan_1"]);
const biharPrathamDelhiRajIds = new Set([
  ...biharPrathamDelhiIds,
  "bcg_rajasthan_1",
]);

export const getTeacherId = (organisation, teacherId) => {
  if (biharIds.has(organisation)) {
    return "teacher_bihar_demo";
  }
  if (organisation === "demo" || organisation === "generic_demo") {
    return "teacher_generic_demo";
  }
  if (organisation === "pratham_demo_1") {
    return "teacher_pratham_demo";
  }
  if (organisation === "delhi_mcd_1") {
    return "teacher_delhi_mcd_1";
  }
  if (organisation === "bcg_rajasthan_1") {
    return teacherId;
  }
  return "teacher_generic_demo";
};

export const getOrganisationId = (organisation) =>
  OrganisationMapping[organisation] || "generic_demo";

export const getDeviceId = (organisation) => {
  if (biharIds.has(organisation)) {
    return "device_bihar_demo";
  }
  if (organisation === "pratham_demo_1") {
    return "device_pratham_demo";
  }
  if (organisation === "delhi_mcd_1") {
    return "device_delhi_mcd_1";
  }
  if (organisation === "bcg_rajasthan_1") {
    return "device_bcg_rajasthan_1";
  }
  if (organisation === "demo" || organisation === "generic_demo") {
    return "device_generic_demo";
  }
  return "device_generic_demo";
};

const getOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i += 1) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return options;
};

export const getClassNumOptions = (organisation) => {
  if (biharPrathamRajIds.has(organisation)) {
    return getOptions(1, 8);
  }
  if (organisation === "delhi_mcd_1") {
    return getOptions(2, 5);
  }
  return getOptions(3, 3);
};

export const getNameLabel = (organisation, t) => {
  if (organisation === "pratham_demo_1") {
    return t("child_name_or_id");
  } else if (organisation === "bcg_rajasthan_1") {
    return t("select_child");
  }
  return t("name");
};

export const getGradeLabel = (organisation, t) => {
  if (biharPrathamRajIds.has(organisation)) {
    return t("class");
  }
  if (organisation === "delhi_mcd_1") {
    return t("grade");
  }
  return t("grade_for_assessment");
};

export const getSchoolIdLabel = (organisation, t) => {
  if (biharPrathamDelhiIds.has(organisation)) {
    return t("school_id");
  } else if (organisation === "bcg_rajasthan_1") {
    return t("select_school");
  }
  return t("my_institution_is");
};

export const getGrade = (organisation, grade) => {
  if (biharPrathamDelhiRajIds.has(organisation)) {
    return grade;
  }
  return 3;
};

export const getTextFieldType = (organisation) => {
  if (biharPrathamIds.has(organisation)) {
    return "number";
  }
  return "text";
};

export const getClassSectionOptions = (organisation) => {
  if (organisation === "delhi_mcd_1" || organisation === "bcg_rajasthan_1") {
    return [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
      { value: "E", label: "E" },
      { value: "F", label: "F" },
      { value: "G", label: "G" },
    ];
  }
  return [];
};

export const getGenderOptions = (organisation, t) => {
  if (organisation === "delhi_mcd_1") {
    return [
      {
        value: "male",
        label: t("boy"),
      },
      {
        value: "female",
        label: t("girl"),
      },
    ];
  }
  return [
    {
      value: "male",
      label: t("male"),
    },
    {
      value: "female",
      label: t("female"),
    },
  ];
};

export const getTeacherLabel = (organisation, t) => {
  if (organisation === "pratham_demo_1") {
    return t("instructor_name");
  } else if (organisation === "bcg_rajasthan_1") {
    return t("select_teacher");
  }
  return t("teacher_name");
};

export function getDayFromDate(date, format) {
  return new Date(date).toLocaleDateString("en-GB", {
    day: format,
  });
}

export function getMonthFromDate(date, format) {
  return new Date(date).toLocaleDateString("en-GB", { month: format });
}

export const getFirstNameAndTruncate = (name) => {
  const firstName = name.split(" ")[0];
  if (firstName.length > 20) {
    return `${firstName.substring(0, 20)}...`;
  }
  return firstName;
};