import { MenuItem, TextField } from "@mui/material";
import React from "react";
import "./CustomDropDown.css";
import useParamValues from "../../utils/useParamValues";

function CustomDropDown({
  selectedValue,
  setSelectedValue,
  options,
  label,
  disabled = false,
}) {
  const { organisation } = useParamValues();
  const handleSelectChange = (event) => {
    if (organisation === "bcg_rajasthan_1") {
      setSelectedValue(event);
    }
    setSelectedValue(event.target.value);
  };

  return (
    <TextField
      style={{
        width: "100%",
      }}
      className={`outlined-drop-down-select ${selectedValue ? "filled" : ""}`}
      select
      label={label}
      value={selectedValue}
      onChange={handleSelectChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          className="drop-down-options"
        >
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CustomDropDown;
