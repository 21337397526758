import { useState, useEffect, useRef } from "react";

function useAudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioElementRef = useRef(null);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (isPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  }, [isPlaying]);

  const isNotPlaying = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (currentTime === duration) {
      setIsPlaying(false);
    }
  }, [currentTime, duration]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = (event) => {
    const target = event.currentTarget;
    if (target) {
      if (target.currentTime >= target.duration) {
        setCurrentTime(0);
        target.currentTime = 0;
        isNotPlaying();
      } else {
        setCurrentTime(target.currentTime);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (
      audioElementRef.current &&
      audioElementRef.current.duration &&
      !Number.isNaN(audioElementRef.current.duration) &&
      audioElementRef.current.duration > 0
    ) {
      setDuration(audioElementRef.current.duration);
    }
  };

  const handleSliderChange = (event) => {
    if (audioElementRef.current) {
      audioElementRef.current.currentTime = event.target.value;
      setCurrentTime(event.target.value);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return {
    isPlaying,
    notPlaying: () => setIsPlaying(false),
    currentTime,
    duration,
    audioElementRef,
    handlePlayPause,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSliderChange,
    formatTime,
    setDuration,
  };
}

export default useAudioPlayer;
