import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./AudioSizeShortError.css";
import ShortRecording from "../../assets/short_recording.svg";

const AudioSizeShortError = ({
  isDialogOpen,
  warningHeading,
  warningMessage,
  onClickRecordAgain,
  t,
}) => {
  const handleCloseEventsForInvalidAudio = () => {
    onClickRecordAgain();
  };

  return (
    <Dialog
      className="audio-size-short-error"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        <img src={ShortRecording} alt="Short" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              paddingTop: "8px",
              color: "#000000",
              fontWeight: "500",
              letterSpacing: "-0.38px",
              lineHeight: "150%",
              fontFamily: "Poppins",
            }}
          >
            {warningHeading}
          </Typography>
          <div
            style={{
              color: "#373737",
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: "4vw",
              lineHeight: "150%",
              marginTop: "4px",
              marginBottom: "14px",
              padding: "0px 8px",
            }}
          >
            {warningMessage}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "12px",
                color: "#FFF",
                fontWeight: "600",
                fontSize: "16px",
                textAlign: "center",
                whiteSpace: "nowrap",
                padding: "8px 0px",
                background: "#E47714",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                handleCloseEventsForInvalidAudio();
              }}
              disableElevation
            >
              {t("retake")}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AudioSizeShortError;
