import { UrlConstants } from "../utils/CommonUtils/constants";

const fetchAssessmentsList = (url) => {
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${UrlConstants.TOKEN}`,
    },
  };

  return fetch(url, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    }
    return res.json();
  });
};

export default fetchAssessmentsList;