import ContentPasteOffOutlinedIcon from "@mui/icons-material/ContentPasteOffOutlined";
import Header from "../../Header";
import { getFirstNameAndTruncate } from "../../utils/helper";

function NoAssessmentsScreen({
  studentName,
  t,
  setAssessmentListData,
  setShowVopaInputFieldsScreen,
  setShowNoAssessmentsScreen,
  setGender,
}) {
  
  return (
    <div>
      <Header
        heading={`${getFirstNameAndTruncate(studentName)}'s Assessment`}
        background="linear-gradient(134.56deg, #E47714 59.55%, rgba(228, 119, 20, 0.4) 152.08%)"
        isSelectLanguage
        handleOnClickBackButton={() => {
          setShowNoAssessmentsScreen(false);
          setShowVopaInputFieldsScreen(true);
          setAssessmentListData(null);
          setGender("");
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: "#373737",
          }}
        >
          <ContentPasteOffOutlinedIcon
            style={{
              fontSize: "55",
            }}
          />
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            textAlign: "center",
            fontFamily: "Open Sans",
          }}
        >
          {t("no_assessment_description", {
            firstName: studentName.split(" ")[0],
          })}
        </div>
      </div>
    </div>
  );
}

export default NoAssessmentsScreen;
