import { Box, CircularProgress, Typography } from "@mui/material";

function CustomLoader({value, color, showValue, size, background }) {
    
    return (
        <Box sx={{display: "inline-flex", position: "relative", width: size ? `${size}px` : "40px", padding: "0", margin: 0, height:size ? `${size}px` : "40px", background: background || "#d7eefa", borderRadius: "50%"}}>
            <CircularProgress 
                color={color || "primary"}
                variant={(value != null) ? "determinate" : "indeterminate"}
                value={value}
                size={size || 40}
            />
            <Box
                sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
                margin: size ? `${size/11.5}px` : "4px",
                borderRadius: "50%",
                }}
            >
                {showValue && <Typography variant="caption" component="div" color="text.secondary" sx={{fontSize: size ? `${size/3.5}px` : "10px"}}>
                    {`${Math.round(value)}%`}
                </Typography>}
            </Box>
        </Box>
    );
}
export default CustomLoader;