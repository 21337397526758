import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import useTranslationSetup from "../../utils/useTranslationSetup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import getSchoolData from '../../fetchApi/getSchoolData'
import "./EnterPasswordScreen.css";
import { UrlConstants } from "../../utils/CommonUtils/constants";
import CustomErrorDialogBox from "../../components/CustomErrorDialogBox/CustomErrorDialogBox";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const EnterPasswordScreen = ({
  setShowVopaInputFieldsScreen,
  setShowEnterPasswordScreen,
  schoolData,
  setSchoolData
}) => {
  const [password, setPassword] = useState("");
  const { t } = useTranslationSetup();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    setLoading(true);
    getSchoolData(
        `${UrlConstants.BASE_URL}${UrlConstants.BASE_PATH_URL}auth-data/school`,
        password
    ).then((response)=>{
        if(response?.status === "ERROR" || response?.status === "UNAUTHENTICATED") {
            setError(response?.status);
        } else {
            setSchoolData(response);
        }
        setLoading(false);
    })
  };

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(()=>{
    if (schoolData !== null) {
        setShowEnterPasswordScreen(false);
        setShowVopaInputFieldsScreen(true);
    }
  }, [schoolData])

  return (
    <>
    {(error === "UNAUTHENTICATED" || error === "ERROR") && <CustomErrorDialogBox
          header={error === "UNAUTHENTICATED" ? t("entered_wrong_password") : t("something_went_wrong")}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: "#D95959",
                borderRadius: "10px",
                marginTop: "16px",
                width: "80px",
                height: "80px",
              }}
            />
          }
          secondaryButtonText={t("try_again")}
          isDialogOpen
          showPrimaryButton={false}
          showSecondaryButton
          onClickSecondaryButton={() => {
            setError(null);
            setPassword("");
          }}
        />}
    <div className="enter-pwd-container">
      <Typography className="enter-pwd-heading-text">
        {t("enter_password")}
      </Typography>
      <Typography className="enter-pwd-heading-text-msg">
        {t("enter_password_message")}
      </Typography>
      <TextField
        type={showPassword ? "text" : "password"}
        label="Password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        className="enter-pwd-text-field"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={t("toggle_password_visibility")}
                onClick={toggleShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        disabled={!password}
        sx={{
          backgroundColor: !password ? "" : "#EB770C",
        }}
        className="enter-pwd-button"
      >
        {loading ? <CircularProgress sx={{
            color: "#fff"
        }}/> : t("next")}
      </Button>
    </div>
    </>
  );
};

export default EnterPasswordScreen;