import Header from "../../Header";
import CustomAssesmentCard from "../../components/CustomAssesmentCard/CustomAssesmentCard";
import getGroupedAssessmentList from "../../utils/getGroupedAssessmentList";
import { getDayFromDate, getFirstNameAndTruncate } from "../../utils/helper";

function AssessmentListScreen({
  studentName,
  assessmentListData,
  setAssessmentListData,
  setShowResultLoadingScreen,
  setShowAssessmentListScreen,
  setShowVopaInputFieldsScreen,
  setAssessmentId,
  setCardClicked,
  setAssessmentNumber,
  t,
  setBackArrow,
  setGender,
}) {
  const handleOnClickEvent = (cardAssessmentId, cardAssessmentNumber) => {
    setCardClicked(true);
    setBackArrow(true);
    setAssessmentId(cardAssessmentId);
    setAssessmentNumber(cardAssessmentNumber);
    setShowAssessmentListScreen(false);
    setShowResultLoadingScreen(true);
  };

  const resultAssessmentList = getGroupedAssessmentList(assessmentListData);

  return (
    <div>
      <Header
        heading={`${getFirstNameAndTruncate(studentName)}'s Assessment`}
        background="linear-gradient(134.56deg, #E47714 59.55%, rgba(228, 119, 20, 0.4) 152.08%)"
        isSelectLanguage
        handleOnClickBackButton={() => {
          setShowAssessmentListScreen(false);
          setShowVopaInputFieldsScreen(true);
          setAssessmentListData(null);
          setGender("");
        }}
      />
      {resultAssessmentList.map((assessment, index) => (
        <div
          key={index}
          style={{
            marginBottom: "40px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontFamily: "Open Sans",
              fontWeight: "600",
              color: "#373737",
              lineHeight: "27px",
              marginBottom: "10px",
              marginLeft: "1.3rem",
              marginTop: "0.2rem",
            }}
          >
            {assessment.month}
          </p>
          {assessment.assessments.map((item) => (
            <div
              key={item.id}
              style={{
                marginBottom: "16px",
              }}
            >
              <CustomAssesmentCard
                day={getDayFromDate(item.created_at, "numeric")}
                assessmentNumber={item.assessment_number}
                paraId="DF4546F63D"
                cwpm={item.cwpm}
                onClick={() =>
                  handleOnClickEvent(item.id, item.assessment_number)
                }
                t={t}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default AssessmentListScreen;
