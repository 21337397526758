import React from "react";
import Lottie from "react-lottie-player";
import micJson from "../../../assets/LottieFiles/mic.json";
import "./MicGif.css";

function MicGif() {
  return (
    <div>
      <Lottie
        loop
        animationData={micJson}
        play
        style={{ width: "100%", height: "132px", margin: "43px 0 43px" }}
      />
    </div>
  );
}

export default MicGif;
