import React from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import CustomCard from "../CustomCard/CustomCard";

function CustomAssesmentCard({
  day,
  assessmentNumber,
  paraId,
  cwpm,
  onClick,
  t,
}) {
  return (
    <div onClick={onClick}>
      <CustomCard>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              background:
                "rgba(228, 119, 20, 1)",
              color: "#fff",
              textAlign: "center",
              padding: "12px 13px",
              borderRadius: "12px 0px 0px 12px",
            }}
          >
            <div>
              <CalendarTodayOutlinedIcon
                style={{
                  fontSize: "14",
                }}
              />
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "300",
                fontFamily: "Open Sans",
                marginTop: "1px",
              }}
            >
              {String(day).length === 1 ? String(day).padStart(2, "0") : day}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: "1",
              marginRight: "1.0rem",
            }}
          >
            <div
              style={{
                fontFamily: "Open Sans",
                fontWeight: "600", // Also need to change to 400 if paraId added
                color: "#373737",
                marginLeft: "11px",
                marginTop: "24px", // In case paraId added just need to change this to 15px
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  marginBottom: "4px",
                }}
              >
                {`${t("assessment")} ${assessmentNumber}`}
              </div>
              <div
                style={{
                  fontSize: "10px",
                  display: "none", // In case paraId added just need to remove this
                }}
              >
                {`ID : ${paraId}`}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  marginRight: "12px",
                  fontFamily: "Open Sans",
                  fontWeight: "400",
                  color: "#373737",
                }}
              >
                <div
                  style={{
                    fontSize: "21px",
                  }}
                >
                  {cwpm}
                </div>
                <div
                  style={{
                    fontSize: "13px",
                  }}
                >
                  cwpm
                </div>
              </div>
              <div>
                <ArrowForwardIosOutlinedIcon
                  style={{
                    fontSize: "14",
                    color: "#373737",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CustomCard>
    </div>
  );
}

export default CustomAssesmentCard;