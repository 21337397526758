import React, { useRef, useEffect, useState } from "react";
import "./VopaInstructionScreen.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Button } from "@mui/material";
import CardsGroupComponent from "../../components/CardsGroupComponent/CardsGroupComponent";
import MicGif from "../../components/GroupedAssets/MicGif/MicGif";
import StudentImgWithHandMobileGif from "../../components/GroupedAssets/StudentImgWithHandMobileGif/StudentImgWithHandMobileGif";
import AudioMovingGif from "../../components/GroupedAssets/AudioMovingGif/AudioMovingGif";
import TimerGif from "../../components/GroupedAssets/TimerGif/TimerGif";
import useTranslationSetup from "../../utils/useTranslationSetup";
import useParamValues from "../../utils/useParamValues";

function VopaInstructionScreen({
  setShowVopaInstructionScreen,
  setShowVopaInputFieldsScreen,
  setShowEnterPasswordScreen
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslationSetup();
  const { appLanguage, organisation } = useParamValues();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const vopaInstructionContent = [
    {
      heading: t("working_mic"),
      content: t("working_mic_description"),
      gif: <MicGif />,
    },
    {
      heading: t("device_proximity"),
      content: t("device_proximity_description"),
      gif: <StudentImgWithHandMobileGif />,
    },
    {
      heading: t("avoid_noise"),
      content: t("avoid_noise_description"),
      gif: <AudioMovingGif />,
    },
    {
      heading: t("finish_the_paragraph"),
      content: t("finish_the_paragraph_description"),
      gif: <TimerGif />,
    },
  ];

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  return (
    <div>
      <div className="vopa-instructions-screen-heading">
        {t("assessment_time")}
      </div>
      <div className="vopa-instructions-screen-desc">
        {t("ensure_the_following_when_conducting_an_assessment")}
      </div>
      <div className="cards-component-vopa-instruction">
        <CardsGroupComponent>
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            pagination={{ clickable: false }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={(swiper) => {
              setCurrentIndex(swiper.activeIndex);
            }}
          >
            {vopaInstructionContent.map((item, index) => (
              <SwiperSlide key={index}>
                <div>{item.gif}</div>
                <div className="vopa-instruction-heading">{item.heading}</div>
                <div className="vopa-instruction-desc">
                  {item.content}
                  {item.heading === t("finish_the_paragraph") ? (
                    <span className="vopa-instruction-desc-span">
                      {" "}
                      {t("read_it_aloud")}
                    </span>
                  ) : null}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="vopa-instruction-screen-button-container">
            <Button
              className="vopa-instruction-screen-back-button"
              ref={prevRef}
              variant="text"
              disabled={currentIndex === 0}
              style={{
                fontSize: appLanguage === "en" ? "12px" : "13px",
                color: currentIndex !== 0 && "#E47714",
              }}
            >
              {t("back")}
            </Button>
            <div className="swiper-paginations">
              {vopaInstructionContent.map((_, index) => (
                <span
                  key={index}
                  className={`swiper-pagination-bullet ${index === currentIndex ? "swiper-pagination-bullet-active" : ""}`}
                  onClick={() => swiperRef.current.slideTo(index)}
                />
              ))}
            </div>
            <Button
              className="vopa-instruction-screen-next-button"
              ref={nextRef}
              variant="contained"
              disableElevation
              disabled={currentIndex === vopaInstructionContent.length - 1}
              style={{
                fontSize: appLanguage === "en" ? "12px" : "13px",
                backgroundColor: currentIndex !== vopaInstructionContent.length - 1 && "#E47714",
              }}>
              {t("next")}
            </Button>
          </div>
        </CardsGroupComponent>
      </div>
      <div className="sticky">
        {currentIndex !== vopaInstructionContent.length - 1 && (
          <div className="seen-instructions-before-text">
            {t("have_seen_instructions_before")}
          </div>
        )}
        <div className="vopa-instruction-button">
          <Button
            variant={
              currentIndex < vopaInstructionContent.length - 1
                ? "outlined"
                : "contained"
            }
            className="vopa-instruction-continue-skip-button"
            style={{
              color:
                currentIndex < vopaInstructionContent.length - 1
                  ? "#E47714"
                  : "#FFF",
              background:
                currentIndex < vopaInstructionContent.length - 1 ? "" : "#E47714",
              margin:
                currentIndex === vopaInstructionContent.length - 1 && "11vh 0 0",
            }}
            disableElevation
            onClick={() => {
              setShowVopaInstructionScreen(false);
              if (organisation === 'bcg_rajasthan_1') {
                setShowEnterPasswordScreen(true);
              } else {
                setShowVopaInputFieldsScreen(true);
              }
            }}
          >
            {currentIndex < vopaInstructionContent.length - 1
              ? t("skip")
              : t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default VopaInstructionScreen;
