import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./SaveResultDialogBox.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import savedSuccessfullyImage from "../../assets/savedSuccessfullyImage.svg";
import fetchStatus from "../../fetchApi/fetchStatus";
import CustomLoader from "../CustomLoader/CustomLoader";
import useParamValues from "../../utils/useParamValues";
import useTranslationSetup from "../../utils/useTranslationSetup";
import { UrlConstants } from "../../utils/CommonUtils/constants";

function SaveResultDialogBox({
  isDialogOpen,
  setDialogOpen,
  setShowResultLoadingScreen,
  setShowOrfTestScreen,
  setShowOrfResultScreen,
  setProcessedData,
  assessmentId,
  setAudioBlob,
  setHideSlider,
  setShowHomeScreen,
  uploadAudioStatus,
  setUploadAudioStatus,
  setStudentName,
  isAudioUploading,
  setAudioUploading,
  setShowErrorDialog,
  setOrfResultStatus,
  setSelectedLaguage,
  setClassNum,
  setSchoolUdise,
  setSaveClicked,
  setProfession,
  setGender,
  setSection,
  setSchoolShift,
}) {
  const { organisation } = useParamValues();
  const { t } = useTranslationSetup();

  const handleReturnToAssessment = () => {
    setDialogOpen(false);
    setHideSlider(false);
    setAudioBlob(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };

  const handleReturnToInputPage = () => {
    setDialogOpen(false);
    setShowOrfTestScreen(false);
    setShowHomeScreen(true);
    setHideSlider(false);
    setAudioBlob(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
    setStudentName("");
    setSelectedLaguage("");
    setProfession("");
    setClassNum("");
    setSchoolUdise("");
    setGender("");
    setSection("");
    setSchoolShift("");
  };

  const renderDialogContent = () => {
    if (isAudioUploading && uploadAudioStatus !== "ERR_INTERNET_DISCONNECTED") {
      return (
        <div style={{ textAlign: "center", padding: "2rem 0px" }}>
          <CustomLoader />
          <div style={{ fontFamily: "Poppins", marginTop: "8px" }}>
            {t("please_wait_while_uploading_audio")}
          </div>
        </div>
      );
    }

    if (uploadAudioStatus === "COMPLETED") {
      return (
        <>
          <DialogTitle
            id="save-result-dialog-title"
            style={{
              backgroundImage: `url(${savedSuccessfullyImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <CheckCircleIcon
              style={{
                color: "#7EB66B",
                margin: "1.6rem 0 5px",
                fontSize: "3.5rem",
              }}
            />
            <Typography
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "400",
                margin: "0 5px 8px",
                fontFamily: "Poppins",
              }}
            >
              {t("saved_successfully")}
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
                padding: "0 12% 0px",
                color: "#373737",
                fontFamily: "Poppins",
              }}
            >
              {t("saved_successfully_description")}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="save-result-dialog-description"
              style={{ textAlign: "center" }}
            >
              <Button
                variant="contained"
                startIcon={<AssignmentOutlinedIcon />}
                style={{
                  height: "46px",
                  textTransform: "none",
                  width: "100%",
                  borderRadius: "10px",
                  margin: "1rem 0 0.8rem",
                  fontWeight: "400",
                  fontSize: "16px",
                  background: "#E47714",
                  fontFamily: "Poppins",
                }}
                disabled={isAudioUploading}
                onClick={() => {
                  setDialogOpen(false);
                  setShowOrfTestScreen(false);
                  setShowResultLoadingScreen(true);
                  fetchStatus(
                    `${UrlConstants.BASE_URL +
                      UrlConstants.BASE_PATH_URL +
                      UrlConstants.ASSESSMENT 
                      }/${assessmentId}?get_audio=true`
                  )
                    .then((data) => {
                      if (data.status === "COMPLETED") {
                        setShowResultLoadingScreen(false);
                        setShowOrfResultScreen(true);
                        setOrfResultStatus(data.status);
                        setProcessedData(organisation === "vopa" ? data.data.result : data.data);
                      } else {
                        setOrfResultStatus(data.status);
                        setShowErrorDialog(true);
                      }
                    })
                    .catch((error) => {
                      setOrfResultStatus(error.status);
                    });
                }}
              >
                {t("see_result")}
              </Button>
            </DialogContentText>
          </DialogContent>
        </>
      );
    }

    return (
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#000000",
              fontWeight: "500",
              letterSpacing: "-1.9%",
              fontFamily: "Poppins",
            }}
          >
            {t("something_went_wrong")}
          </Typography>
          <div style={{ display: "flex", marginTop: "22px" }}>
            <Button
              variant="contained"
              startIcon={<LoopOutlinedIcon className="retake-icon" />}
              style={{
                height: "48px",
                textTransform: "none",
                width: "100%",
                borderRadius: "12px",
                color: "#fff",
                fontWeight: "400",
                fontSize: "16px",
                background: "rgba(228, 119, 20, 1)",
                fontFamily: "Poppins",
              }}
              disableElevation
              onClick={handleReturnToAssessment}
            >
              {t("retake")}
            </Button>
          </div>
          <div style={{ display: "flex", marginTop: "16px" }}>
            <Button
              variant="text"
              startIcon={
                <KeyboardReturnIcon sx={{ paddingRight: "2px" }} />
              }
              style={{
                height: "48px",
                textTransform: "none",
                width: "100%",
                borderRadius: "12px",
                color: "#E47714",
                background: "#FFF",
                fontWeight: "400",
                fontSize: "16px",
                textAlign: "center",
                whiteSpace: "nowrap",
                border: "1px solid #E47714",
                fontFamily: "Poppins",
              }}
              onClick={handleReturnToInputPage}
              disableElevation
            >
              {t("new_student")}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    );
  };

  return (
    <Dialog
      className="saveResult"
      open={isDialogOpen}
      aria-labelledby="save-result-title"
      aria-describedby="save-result-dialog-description"
      style={{ borderRadius: "20px" }}
    >
      {renderDialogContent()}
    </Dialog>
  );
}

export default SaveResultDialogBox;
