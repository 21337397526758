import React, { useEffect } from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WelcomeScreenImg from "../../assets/demo_welcome_screen_image.svg";
import "./VopaWelcomeScreen.css";
import useTranslationSetup from "../../utils/useTranslationSetup";
import CloudTop from "../../assets/cloud-top.svg";
import CloudBottom from "../../assets/cloudBottom.svg";

function VopaWelcomeScreen({
  setShowVopaWelcomeScreen,
  setShowNeedPermissionsScreen,
  audioPermissionStatus,
  locationPermissionStatus,
  setLocationPermissionStatus,
  setLatitude,
  setLongitude,
  setShowVopaInstructionScreen,
}) {
  const { t } = useTranslationSetup();

  useEffect(() => {
    if (locationPermissionStatus === "GRANTED" && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationPermissionStatus("GRANTED");
        },
        () => {
          setLocationPermissionStatus("DENIED");
        }
      );
    } else {
      setLocationPermissionStatus("PROMPT");
    }
  }, [locationPermissionStatus]);

  function handleNavigateToNeedPermissionsScreen() {
    setShowVopaWelcomeScreen(false);
    setShowNeedPermissionsScreen(true);
  }

  function handleNavigateToVopaAboutScreen() {
    setShowVopaWelcomeScreen(false);
    // setShowVopaAboutScreen(true);
    setShowVopaInstructionScreen(true);
  }

  return (
    <div>
      <div className="cloudTop">
        <img src={CloudTop} alt="Clouds" />
      </div>
      <div
        style={{
          textAlign: "center",
          margin: "25% 0 0 0",
        }}
      >
        <img
          style={{
            height: "33.75vh",
            width: "80vw",
          }}
          src={WelcomeScreenImg}
          alt="vopa_welcome_screen_img"
        />
      </div>
      <div className="vopa-welcome-screen-container">
        <div className="vopa-welcome-screen-heading-text">
          {t("welcome_to_orf")}
        </div>
        <div className="vopa-welcome-screen-content-wrapper">
          <div className="vopa-welcome-screen-content">{t("orf_desc")}</div>
        </div>
      </div>
      <div
        className="sticky-button"
        style={{
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          style={{
            height: "46px",
            width: "60%",
            textTransform: "none",
            borderRadius: "10px",
            color: "#fff",
            fontWeight: "500",
            fontSize: "16px",
            fontFamily: "Poppins",
            background: "#E47714",
          }}
          disableElevation
          onClick={() => {
            if (
              audioPermissionStatus === "GRANTED" &&
              locationPermissionStatus === "GRANTED"
            ) {
              handleNavigateToVopaAboutScreen();
            } else {
              handleNavigateToNeedPermissionsScreen();
            }
            // handleNavigateToVopaAboutScreen();
          }}
        >
          {t("next")}
        </Button>
      </div>
      <div className="cloudsBottom">
        <img src={CloudBottom} alt="cloud" />
      </div>
    </div>
  );
}

export default VopaWelcomeScreen;
