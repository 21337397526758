import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import "./DeleteAudioDialog.css";
import KeyboardVoiceRoundedIcon from "@mui/icons-material/KeyboardVoiceRounded";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import useTranslationSetup from "../../utils/useTranslationSetup";

function DeleteAudioDialog({
  isDialogOpen,
  setDialogOpen,
  setHideSlider,
  setAudioBlob,
}) {
  const { t } = useTranslationSetup();
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      className="deleteAudio"
      open={isDialogOpen}
      aria-labelledby="delete-audio-title"
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        id="delte-audio-dialog-title"
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
          }}
        >
          <KeyboardVoiceRoundedIcon
            style={{
              color: "#C1C1C1",
              width: "46px",
              height: "46px",
            }}
          />
          <CloseOutlinedIcon
            style={{
              position: "absolute",
              color: "#E70000",
              left: "23",
              top: "-2",
              zIndex: "10",
              height: "14px",
              width: "14px",
              background: "#fff",
              borderRadius: "4px",
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="save-result-dialog-description"
          style={{ textAlign: "center" }}
        >
          <Typography
            style={{
              textAlign: "center",
              fontSize: "20px",
              paddingTop: "5px",
              color: "#000000",
              fontWeight: "600",
              fontFamily: "Poppins",
            }}
          >
            {t("retake_audio")}
          </Typography>

          <Typography
            style={{
              textAlign: "center",
              fontSize: "14px",
              paddingTop: "5px",
              color: "#373737",
              fontWeight: "400",
              paddingLeft: "24px",
              paddingRight: "24px",
              fontFamily: "Poppins",
            }}
          >
            {t("retake_audio_desc")}
          </Typography>
          <div
            style={{
              display: "flex",
              marginTop: "22px",
            }}
          >
            <Button
              className="deleteAudioIcon"
              variant="text"
              startIcon={<CloseOutlinedIcon />}
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "10px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#E70000",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              startIcon={
                <LoopOutlinedIcon
                  sx={{
                    transform: "rotateY(180deg) rotate(132deg)",
                  }}
                />
              }
              style={{
                textTransform: "none",
                width: "100%",
                borderRadius: "8px",
                color: "#fff",
                fontWeight: "400",
                fontSize: "16px",
                background: "#E47714",
                fontFamily: "Poppins",
              }}
              onClick={() => {
                setAudioBlob(null);
                handleClose();
                setHideSlider(false);
              }}
              disableElevation
            >
              {t("retake")}
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteAudioDialog;
