import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import VopaWelcomeScreen from "../screens/VopaWelcomeScreen/VopaWelcomeScreen";
import VopaInstructionScreen from "../screens/VopaInstructionScreen/VopaInstructionScreen";
import NeedPermissionsScreen from "../screens/NeedPermissionsScreen/NeedPermissionsScreen";
import ResultLoadingScreen from "../ResultLoadingScreen";
import EnterOrfApplicationLoadingScreen from "../EnterOrfApplicationLoadingScreen";
import OrfResultScreen from "../OrfResultScreen";
import OrfTestScreen from "../OrfTestScreen";
import useTranslationSetup from "../utils/useTranslationSetup";
import useParamValues from "../utils/useParamValues";
import DemoResultsScreen from "../screens/DemoResultsScreen/DemoResultsScreen";
import SelectAssessmentLangScreen from "../screens/SelectAssessmentLangScreen/SelectAssessmentLangScreen";
import VopaInputFieldsWrapper from "../screens/VopaInputFieldsScreen/VopaInputFieldWrapper";
import {
  getDeviceId,
  getGrade,
  getOrganisationId,
  getTeacherId,
} from "../utils/helper";
import AssessmentListScreen from "../screens/AssessmentListScreen/AssessmentListScreen";
import NoAssessmentsScreen from "../screens/NoAssessmentsScreen/NoAssessmentsScreen";
import EnterPasswordScreen from "../screens/EnterPasswordScreen/EnterPasswordScreen";

function VopaApp({ organisation = "demo", setSplash = () => {} }) {
  const { i18n } = useTranslation();
  const { t } = useTranslationSetup();
  const { appLanguage } = useParamValues();
  const [showVopaWelcomeScreen, setShowVopaWelcomeScreen] = useState(true);
  const [showNeedPermissionsScreen, setShowNeedPermissionsScreen] =
    useState(false);
  const [showVopaAboutScreen, setShowVopaAboutScreen] = useState(false);
  const [showVopaInstructionScreen, setShowVopaInstructionScreen] =
    useState(false);
  const [showVopaInputFieldsScreen, setShowVopaInputFieldsScreen] =
    useState(false);
  const [showAssessmentLangScreen, setShowAssessmentLangScreen] =
    useState(false);
  const [hideSlider, setHideSlider] = useState(false);
  const [hideArrow, setHideArrow] = useState(false);
  const [showSplashLoadingScreen, setShowSplashLoadingScreen] = useState(false);
  const [showOrfTestScreen, setShowOrfTestScreen] = useState(false);
  const [showResultLoadingScreen, setShowResultLoadingScreen] = useState(false);
  const [showOrfResultScreen, setShowOrfResultScreen] = useState(false);
  const [audioBlobForAudioPlayer, setAudioBlobForAudioPlayer] = useState(null);
  const [paraData, setParaData] = useState("");
  const [processedData, setProcessedData] = useState(null);
  const [orfResultStatus, setOrfResultStatus] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [audioPermissionStatus, setAudioPermissionStatus] = useState(null);
  const [locationPermissionStatus, setLocationPermissionStatus] =
    useState(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isAudioUploading, setAudioUploading] = useState(false);
  const [uploadAudioStatus, setUploadAudioStatus] = useState(null);
  const [studentName, setStudentName] = useState("");
  const [classNum, setClassNum] = useState("");
  const [schoolUdise, setSchoolUdise] = useState("");
  const [medium, setMedium] = useState("");
  const [profession, setProfession] = useState("");
  const [assessmentLang, setAssessmentLang] = useState(null);
  const [studentId, setStudentId] = useState("");
  const [teacherId, setTeacherId] = useState("");

  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [instructorName, setInstructorName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [readingLevel, setReadingLevel] = useState("");
  const [requestBody, setRequestBody] = useState({});
  const [feedbackResponse, setFeedbackResponse] = useState(null);
  const [section, setSection] = useState("");
  const [schoolShift, setSchoolShift] = useState("");

  const [showEnterPasswordScreen, setShowEnterPasswordScreen] = useState(false);
  const [showAssessmentListScreen, setShowAssessmentListScreen] =
    useState(false);
  const [showNoAssessmentsScreen, setShowNoAssessmentsScreen] = useState(false);
  const [assessmentListData, setAssessmentListData] = useState(null);
  const [cardClicked, setCardClicked] = useState(false);
  const [assessmentNumber, setAssessmentNumber] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [backArrow, setBackArrow] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [currentMode, setCurrentMode] = useState("assessment");
  const [schoolData, setSchoolData] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("app_language")) {
      i18n.changeLanguage(localStorage.getItem("app_language"));
    } else {
      i18n.changeLanguage(appLanguage);
    }
  }, [appLanguage, i18n]);

  useEffect(() => {
    if (showLoadingScreen) {
      if (assessmentListData !== null && assessmentListData.length > 0) {
        setShowLoadingScreen(false);
        setShowAssessmentListScreen(true);
      } else if (
        assessmentListData !== null &&
        assessmentListData.length === 0
      ) {
        setShowLoadingScreen(false);
        setShowNoAssessmentsScreen(true);
      }
    }
  }, [assessmentListData, showLoadingScreen]);

  useEffect(() => {
    if (
      paraData !== "" &&
      (organisation !== "pratham_demo_1" || feedbackResponse !== null)
    ) {
      setShowSplashLoadingScreen(false);
      setShowOrfTestScreen(true);
    }
  }, [paraData, feedbackResponse, organisation]);

  useEffect(() => {
    if (processedData != null) {
      setShowResultLoadingScreen(false);
      setShowOrfTestScreen(false);
      setShowOrfResultScreen(true);
    }
  }, [processedData]);

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "prompt") {
        setLocationPermissionStatus("PROMPT");
      } else if (result.state === "granted") {
        setLocationPermissionStatus("GRANTED");
      } else if (result.state === "denied") {
        setLocationPermissionStatus("DENIED");
      }
    });
    navigator.permissions.query({ name: "microphone" }).then((result) => {
      if (result.state === "prompt") {
        setAudioPermissionStatus("PROMPT");
      } else if (result.state === "granted") {
        setAudioPermissionStatus("GRANTED");
      } else if (result.state === "denied") {
        setAudioPermissionStatus("DENIED");
      }
    });
  }, []);

  return (
    <div>
      {showVopaWelcomeScreen && (
        <VopaWelcomeScreen
          showVopaWelcomeScreen={showVopaWelcomeScreen}
          setShowVopaWelcomeScreen={setShowVopaWelcomeScreen}
          showNeedPermissionsScreen={showNeedPermissionsScreen}
          setShowNeedPermissionsScreen={setShowNeedPermissionsScreen}
          showVopaAboutScreen={showVopaAboutScreen}
          setShowVopaAboutScreen={setShowVopaAboutScreen}
          audioPermissionStatus={audioPermissionStatus}
          setAudioPermissionStatus={setAudioPermissionStatus}
          locationPermissionStatus={locationPermissionStatus}
          setLocationPermissionStatus={setLocationPermissionStatus}
          latitude={latitude}
          setLatitude={setLatitude}
          longitude={longitude}
          setLongitude={setLongitude}
          showVopaInstructionScreen={showVopaInstructionScreen}
          setShowVopaInstructionScreen={setShowVopaInstructionScreen}
        />
      )}
      {showNeedPermissionsScreen &&
        (locationPermissionStatus !== "GRANTED" ||
          audioPermissionStatus !== "GRANTED") && (
          <NeedPermissionsScreen
            showNeedPermissionsScreen={showNeedPermissionsScreen}
            setShowNeedPermissionsScreen={setShowNeedPermissionsScreen}
            showVopaAboutScreen={showVopaAboutScreen}
            setShowVopaAboutScreen={setShowVopaAboutScreen}
            latitude={latitude}
            setLatitude={setLatitude}
            longitude={longitude}
            setLongitude={setLongitude}
            showVopaInstructionScreen={showVopaInstructionScreen}
            setShowVopaInstructionScreen={setShowVopaInstructionScreen}
          />
        )}
      {showVopaInstructionScreen && (
        <VopaInstructionScreen
          setShowVopaInstructionScreen={setShowVopaInstructionScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setShowEnterPasswordScreen={setShowEnterPasswordScreen}
        />
      )}
      {showEnterPasswordScreen && (
        <EnterPasswordScreen
          setShowEnterPasswordScreen={setShowEnterPasswordScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          schoolData={schoolData}
          setSchoolData={setSchoolData}
        />
      )}
      {showVopaInputFieldsScreen && (
        <VopaInputFieldsWrapper
          name={studentName}
          setName={setStudentName}
          classNum={classNum}
          setClassNum={setClassNum}
          schoolUdise={schoolUdise}
          setSchoolUdise={setSchoolUdise}
          medium={medium}
          setMedium={setMedium}
          profession={profession}
          setProfession={setProfession}
          showVopaInputFieldsScreen={showVopaInputFieldsScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setShowAssessmentLangScreen={setShowAssessmentLangScreen}
          setStudentId={setStudentId}
          gender={gender}
          setGender={setGender}
          age={age}
          setAge={setAge}
          instructorName={instructorName}
          setInstructorName={setInstructorName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          readingLevel={readingLevel}
          setReadingLevel={setReadingLevel}
          requestBody={requestBody}
          setRequestBody={setRequestBody}
          organisation={organisation}
          section={section}
          setSection={setSection}
          schoolShift={schoolShift}
          setSchoolShift={setSchoolShift}
          setShowLoadingScreen={setShowLoadingScreen}
          setCurrentMode={setCurrentMode}
          setTeacherId={setTeacherId}
          schoolData={schoolData}
        />
      )}
      {showAssessmentLangScreen && (
        <SelectAssessmentLangScreen
          setShowAssessmentLangScreen={setShowAssessmentLangScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          assessmentLang={assessmentLang}
          setAssessmentLang={setAssessmentLang}
        />
      )}
      {showLoadingScreen && (
        <EnterOrfApplicationLoadingScreen
          studentName={studentName}
          t={t}
          mode={currentMode}
          setAssessmentListData={setAssessmentListData}
          studentId={studentId}
          assessmentLang={{ value: "hi" }}
          setShowSplashLoadingScreen={setShowLoadingScreen}
          setAssessmentLang={setAssessmentLang}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
        />
      )}

      {showAssessmentListScreen && (
        <AssessmentListScreen
          studentName={studentName}
          assessmentListData={assessmentListData}
          setAssessmentListData={setAssessmentListData}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          setShowNoAssessmentsScreen={setShowAssessmentListScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setCardClicked={setCardClicked}
          setAssessmentNumber={setAssessmentNumber}
          t={t}
          setAssessmentId={setAssessmentId}
          setShowOrfResultScreen={setShowOrfResultScreen}
          setBackArrow={setBackArrow}
          setGender={setGender}
        />
      )}
      {showNoAssessmentsScreen && (
        <NoAssessmentsScreen
          studentName={studentName}
          t={t}
          setAssessmentListData={setAssessmentListData}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setShowNoAssessmentsScreen={setShowNoAssessmentsScreen}
          setGender={setGender}
        />
      )}

      {showSplashLoadingScreen && (
        <EnterOrfApplicationLoadingScreen
          setParaData={setParaData}
          studentName={studentName}
          setStudentName={setStudentName}
          showVopaInputFieldsScreen={showVopaInputFieldsScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          showSplashLoadingScreen={showSplashLoadingScreen}
          setShowSplashLoadingScreen={setShowSplashLoadingScreen}
          selectedLaguage={medium}
          setSelectedLaguage={setMedium}
          profession={profession}
          setProfession={setProfession}
          assessmentLang={assessmentLang}
          setAssessmentLang={setAssessmentLang}
          setFeedbackResponse={setFeedbackResponse}
          organisation={organisation}
          mode={currentMode}
          feedbackData={{
            feedback: {
              instructor_name: instructorName,
              instructor_phone_number: phoneNumber,
              gender,
              age,
              reading_level: readingLevel,
              student_name: studentName,
              grade_section: section,
              school_shift: schoolShift,
            },
          }}
          requestBody={{
            student_id: studentId,
            teacher_id: getTeacherId(organisation, teacherId),
            lang: assessmentLang.value,
            grade: getGrade(organisation, classNum),
            organisation_id: getOrganisationId(organisation),
            device_id: getDeviceId(organisation),
            school_id: schoolUdise,
            lat: latitude,
            long: longitude,
            student_grade: classNum,
          }}
        />
      )}
      {showOrfTestScreen && !showSplashLoadingScreen && (
        <OrfTestScreen
          hideArrow={hideArrow}
          setHideArrow={setHideArrow}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showOrfTestScreen={showOrfTestScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          paragraph={paraData.paragraph}
          showOrfResultScreen={showOrfResultScreen}
          setShowOrfResultScreen={setShowOrfResultScreen}
          processedData={processedData}
          setProcessedData={setProcessedData}
          assessmentId={paraData.id}
          showHomeScreen={showVopaInputFieldsScreen}
          setShowHomeScreen={setShowVopaInputFieldsScreen}
          studentName={studentName}
          setStudentName={setStudentName}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          selectedLaguage={medium}
          setSelectedLaguage={setMedium}
          classNum={classNum}
          setClassNum={setClassNum}
          schoolUdise={schoolUdise}
          setSchoolUdise={setSchoolUdise}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          setProfession={setProfession}
          assessmentLang={assessmentLang}
          setGender={setGender}
          setSection={setSection}
          setSchoolShift={setSchoolShift}
        />
      )}
      {showResultLoadingScreen && (
        <ResultLoadingScreen
          showResultLoadingScreen={showResultLoadingScreen}
          setShowResultLoadingScreen={setShowResultLoadingScreen}
          showHomeScreen={showVopaInputFieldsScreen}
          setShowHomeScreen={setShowVopaInputFieldsScreen}
          audioBlobForAudioPlayer={audioBlobForAudioPlayer}
          setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
          hideSlider={hideSlider}
          setHideSlider={setHideSlider}
          orfResultStatus={orfResultStatus}
          setOrfResultStatus={setOrfResultStatus}
          saveClicked={saveClicked}
          setSaveClicked={setSaveClicked}
          isAudioUploading={isAudioUploading}
          setAudioUploading={setAudioUploading}
          uploadAudioStatus={uploadAudioStatus}
          setUploadAudioStatus={setUploadAudioStatus}
          showOrfTestScreen={showOrfTestScreen}
          setShowAssessmentLangScreen={setShowAssessmentLangScreen}
          setShowOrfTestScreen={setShowOrfTestScreen}
          setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
          setName={setStudentName}
          setProfession={setProfession}
          setSchoolUdise={setSchoolUdise}
          setClassNum={setClassNum}
          setInstructorName={setInstructorName}
          setPhoneNumber={setPhoneNumber}
          setGender={setGender}
          setAge={setAge}
          setReadingLevel={setReadingLevel}
          setAssessmentLang={setAssessmentLang}
          setAssessmentId={setAssessmentId}
          assessmentId={assessmentId}
          cardClicked={cardClicked}
          setCardClicked={setCardClicked}
          setShowAssessmentListScreen={setShowAssessmentListScreen}
          assessmentNumber={assessmentNumber}
          studentName={studentName}
          setShowOrfResultScreen={setShowOrfResultScreen}
          setProcessedData={setProcessedData}
          setBackArrow={setBackArrow}
          organisation={organisation}
          mode={currentMode}
        />
      )}
      {showOrfResultScreen &&
        (organisation === "vopa" ? (
          <OrfResultScreen
            heading={t("student_orf_result", {
              firstName: studentName.split(" ")[0],
            })}
            lastCwpm={0}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            processedData={processedData}
            showOrfResultScreen={showOrfResultScreen}
            setShowOrfResultScreen={setShowOrfResultScreen}
            showHomeScreen={showVopaInputFieldsScreen}
            setShowHomeScreen={setShowVopaInputFieldsScreen}
            hideSlider={hideSlider}
            setHideSlider={setHideSlider}
            studentName={studentName}
            setStudentName={setStudentName}
            selectedLaguage={medium}
            setSelectedLaguage={setMedium}
            classNum={classNum}
            setClassNum={setClassNum}
            schoolUdise={schoolUdise}
            setSchoolUdise={setSchoolUdise}
            saveClicked={saveClicked}
            setSaveClicked={setSaveClicked}
            isAudioUploading={isAudioUploading}
            setAudioUploading={setAudioUploading}
            uploadAudioStatus={uploadAudioStatus}
            setUploadAudioStatus={setUploadAudioStatus}
          />
        ) : (
          <DemoResultsScreen
            name={studentName}
            classNum={classNum}
            processedData={processedData}
            audioBlobForAudioPlayer={audioBlobForAudioPlayer}
            setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
            setSplash={setSplash}
            setShowOrfResultScreen={setShowOrfResultScreen}
            setShowAssessmentLangScreen={setShowAssessmentLangScreen}
            setAssessmentLang={setAssessmentLang}
            setShowVopaInputFieldsScreen={setShowVopaInputFieldsScreen}
            setHideSlider={setHideSlider}
            setOrfResultStatus={setOrfResultStatus}
            setUploadAudioStatus={setUploadAudioStatus}
            setAudioUploading={setAudioUploading}
            setSaveClicked={setSaveClicked}
            setName={setStudentName}
            setClassNum={setClassNum}
            setProfession={setProfession}
            setSchoolUdise={setSchoolUdise}
            setGender={setGender}
            setAge={setAge}
            setInstructorName={setInstructorName}
            setPhoneNumber={setPhoneNumber}
            setReadingLevel={setReadingLevel}
            setSection={setSection}
            setSchoolShift={setSchoolShift}
            assessmentId={assessmentId}
            setShowAssessmentListScreen={setShowAssessmentListScreen}
            setBackArrow={setBackArrow}
            backArrow={backArrow}
            setAssessmentListData={setAssessmentListData}
            setCardClicked={setCardClicked}
            setCurrentMode={setCurrentMode}
          />
        ))}
    </div>
  );
}

export default VopaApp;
