import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { IconButton, Slider } from "@mui/material";
import "./CustomAudioPlayer.css";

function CustomAudioPlayer({
  isPlaying,
  currentTime,
  duration,
  audioElementRef,
  handlePlayPause = () => {},
  handleTimeUpdate = () => {},
  handleSliderChange = () => {},
  formatTime = () => {},
  audioBlobForAudioPlayer,
  setDuration,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {currentTime === duration ? (
        <IconButton onClick={handlePlayPause}>
          <PlayArrowIcon fontSize="medium" />
        </IconButton>
      ) : (
        <div className="audio-player-icon">
          <IconButton onClick={handlePlayPause}>
            {isPlaying ? (
              <PauseIcon fontSize="medium" />
            ) : (
              <PlayArrowIcon fontSize="larmediumge" />
            )}
          </IconButton>
        </div>
      )}
      <audio
        ref={audioElementRef}
        onDurationChange={(e) => {
          setDuration(e.currentTarget.duration);
        }}
        onTimeUpdate={(e) => {
          handleTimeUpdate(e);
        }}
        onLoadedMetadata={(e) => {
          e.currentTarget.currentTime = 1e101;
        }}
        autoPlay={isPlaying}
        preload="metadata"
        
      >
        <source
          src={typeof(audioBlobForAudioPlayer) === "string" ? audioBlobForAudioPlayer : URL.createObjectURL(audioBlobForAudioPlayer)}
          type="audio/wav"
        />
        <track kind="captions" src="path/to/captions.vtt" srcLang="en" label="English" />
      </audio>
      <div
        style={{
          width: "70%",
          alignItems: "center",
        }}
        className="audio-slider"
      >
        <Slider
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          aria-label="Audio slider"
        />
      </div>
      <div className="duration">{formatTime(duration)}</div>
    </div>
  );
}

export default CustomAudioPlayer;
