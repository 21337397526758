import React, { useState, useEffect, useRef } from "react";
import "./DemoResultsScreen.css";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Button } from "@mui/material";
import congratulatoryImg from "../../assets/congratulatory-img.svg";
import InfoCards from "../../components/InfoCards/InfoCards";
import countIcn from "../../assets/countIcon.svg";
import wordsIcn from "../../assets/wordsIcon.svg";
import quickSightIcn from "../../assets/quickSightIcon.svg";
import ResultCard from "../../components/ResultCard/ResultCard";
import correct from "../../assets/correct.svg";
import missed from "../../assets/missed.svg";
import extra from "../../assets/extra.svg";
import incorrect from "../../assets/incorrect.svg";
import enSpreadedLetters from "../../assets/en_spreaded_letters.svg";
import hiSpreadedLetters from "../../assets/hi_spreaded_letters.svg";
import AudioPlayer from "../../AudioPlayer";
import DetailedResultCard from "../../components/DetailedResultCard/DetailedResultCard";
import correctPercentageIcn from "../../assets/correctPercentageIcn.svg";
import infoIcon from "../../assets/info-icon.svg";
import useTranslationSetup from "../../utils/useTranslationSetup";
import ArrowIcon from "../../assets/keyboard_backspace.svg";

function DemoResultsScreen({
  name,
  classNum,
  processedData,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer = () => {},
  setShowOrfResultScreen,
  setShowAssessmentLangScreen,
  setAssessmentLang,
  setShowVopaInputFieldsScreen,
  setHideSlider,
  setOrfResultStatus,
  setUploadAudioStatus,
  setAudioUploading,
  setSaveClicked,
  setName,
  setClassNum,
  setProfession,
  setSchoolUdise,
  setGender,
  setAge,
  setInstructorName,
  setPhoneNumber,
  setReadingLevel,
  setSection,
  setSchoolShift,
  setShowAssessmentListScreen,
  setBackArrow,
  backArrow,
  setAssessmentListData,
  setCardClicked,
  setCurrentMode,
}) {
  const [expandText, setExpandText] = useState(false);
  const { t } = useTranslationSetup();
  const scrollToRef = useRef(null);
  const [scrollCardIndex, setScrollCardIndex] = useState(null);
  const [scrollTrigger, setScrollTrigger] = useState(0);

  const handleBackClick = () => {
    setShowOrfResultScreen(false);
    setShowAssessmentListScreen(true);
    setBackArrow(false);
    setCardClicked(false);
  };

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [scrollCardIndex, scrollToRef, scrollTrigger]);

  const handleQuickSightCardOnClick = (i) => {
    let newIndex;
    switch (i) {
      case t("correct_words"):
        newIndex = 0;
        break;
      case t("missed_words"):
        newIndex = 1;
        break;
      case t("extra_words"):
        newIndex = 2;
        break;
      case t("incorrect_words"):
        newIndex = 3;
        break;
      default:
        newIndex = null;
    }

    setScrollCardIndex(newIndex);
    setScrollTrigger((prev) => prev + 1);
  };

  const handleNavigateToSelectAssessmentLangScreen = () => {
    setCardClicked(false);
    setShowOrfResultScreen(false);
    setShowAssessmentLangScreen(true);
    setAssessmentLang(null);
    setAudioBlobForAudioPlayer(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
    setBackArrow(false);
    setAssessmentListData(null);
    setCurrentMode("assessment");
  };

  const handleNavigateToInputFieldsScreen = () => {
    setCardClicked(false);
    setShowOrfResultScreen(false);
    setShowVopaInputFieldsScreen(true);
    setName("");
    setClassNum("");
    setProfession("");
    setSchoolUdise("");
    setGender("");
    setAge("");
    setInstructorName("");
    setPhoneNumber("");
    setReadingLevel("");
    setSection("");
    setSchoolShift("");
    setAssessmentLang(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
    setBackArrow(false);
    setAssessmentListData(null);
  };

  const detailedResult = [
    {
      index: 3,
      heading: t("incorrect_words"),
      subHeading: t("incorrect_words_detail"),
      bgColor: "rgba(212, 43, 83, 0.13)",
      innerBorderColor: "#D42B53",
      icon: incorrect,
      words: processedData.result.incorrect_words,
    },
    {
      index: 0,
      heading: t("correct_words"),
      subHeading: t("correct_words_detail"),
      bgColor: "rgba(126, 182, 107, 0.17)",
      innerBorderColor: "#7EB66B",
      icon: correct,
      words: processedData.result.correct_words,
    },
    {
      index: 1,
      heading: t("missed_words"),
      subHeading: t("missed_words_detail"),
      bgColor: "rgba(154, 23, 201, 0.07)",
      innerBorderColor: "#9A17C9",
      icon: missed,
      words: processedData.result.missed_words,
    },
    {
      index: 2,
      heading: t("extra_words"),
      subHeading: t("extra_words_detail"),
      bgColor: "rgba(95, 188, 240, 0.13)",
      innerBorderColor: "#5FBCF0",
      icon: extra,
      words: processedData.result.extra_words,
    },
  ];

  const formattedPercentage = processedData?.result?.correct_percentage
    ? (processedData.result.correct_percentage * 100).toFixed(0)
    : 0;

  return (
    <div className="resultPage">
      {backArrow && (
        <div>
          <img
            src={ArrowIcon}
            alt="Back"
            width="20"
            height="20"
            className="back-arrow"
            onClick={handleBackClick}
          />
        </div>
      )}
      <div className="header">
        <div className="header-text">{name}</div>
        <div className="header-grade-text">
          {t("grade")} {classNum}
        </div>
      </div>
      <hr className="divider" />
      <div className="imageContainer">
        <img src={congratulatoryImg} alt="Result" />
      </div>
      <div className="infoCardsContainer">
        <InfoCards
          icon={countIcn}
          info={t("cwpm")}
          value={`${processedData.result.cwpm}`}
        />
        <div className="info-icon-container">
          <img src={infoIcon} className="info-icon" alt="Info Icon" />
          <div className="cwpm-info">{t("cwpm_correct_words_per_minute")}</div>
        </div>
        <InfoCards
          icon={wordsIcn}
          info={t("words_read_aloud")}
          value={`${processedData.result.total_words_read}/${processedData.result.total_words}`}
        />
        <InfoCards
          icon={correctPercentageIcn}
          info={t("accuracy")}
          value={`${formattedPercentage}%`}
        />
        <div className="info-icon-container">
          <img src={infoIcon} className="info-icon" alt="Info Icon" />
          <div className="cwpm-info">{t("accuracy_desc")}</div>
        </div>
      </div>
      <div className="quicksight-card">
        <div className="quicksight-card-header">
          {t("quick_sight")}
          <img src={quickSightIcn} alt="Quick Sight" />
        </div>
        <div className="quicksight-grid">
          <ResultCard
            heading={t("correct_words")}
            headingColor="#7EB66B"
            bgColor="rgba(126, 182, 107, 0.12)"
            value={processedData.result.correct_words.length}
            image={correct}
            handleQuickSightCardOnClick={handleQuickSightCardOnClick}
          />
          <ResultCard
            heading={t("missed_words")}
            headingColor="#9A17C8"
            bgColor="rgba(154, 23, 200, 0.12)"
            value={processedData.result.missed_words.length}
            image={missed}
            handleQuickSightCardOnClick={handleQuickSightCardOnClick}
          />
          <ResultCard
            heading={t("extra_words")}
            headingColor="#5FBCF0"
            bgColor="rgba(95, 188, 240, 0.12)"
            value={processedData.result.extra_words.length}
            image={extra}
            handleQuickSightCardOnClick={handleQuickSightCardOnClick}
          />
          <ResultCard
            heading={t("incorrect_words")}
            headingColor="#E70000"
            bgColor="rgba(212, 43, 83, 0.12)"
            value={processedData.result.incorrect_words.length}
            image={incorrect}
            handleQuickSightCardOnClick={handleQuickSightCardOnClick}
          />
        </div>
      </div>
      <div className="paragraph-wrapper-card">
        <div className="paragraph-card">
          <div className="para-heading">{t("paragraph")}</div>
          {processedData?.audio_presigned_url && (
            <div className="audio-player-wrapper">
              <div className="audio-player">
                <AudioPlayer
                  audioBlobForAudioPlayer={processedData?.audio_presigned_url}
                  setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
                  testedAudioElementRef={false}
                />
              </div>
            </div>
          )}
          <div
            className="paragraph-para"
            style={{ WebkitLineClamp: !expandText ? 3 : "" }}
            onClick={() => setExpandText(!expandText)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                setExpandText(!expandText);
              }
            }}
            tabIndex="0"
            role="button"
          >
            {processedData.paragraph}
          </div>
        </div>
      </div>
      <div className="detailed-result-card">
        <div className="detailed-result-card-heading">
          {t("detailed_result")}
        </div>
        <div className="detailed-result-grid">
          {detailedResult.map((res) => (
            <div
              key={res.index}
              ref={res.index === scrollCardIndex ? scrollToRef : null}
            >
              <DetailedResultCard
                key={res.index}
                index={res.index}
                heading={res.heading}
                subHeading={res.subHeading}
                bgColor={res.bgColor}
                icon={res.icon}
                innerBorderColor={res.innerBorderColor}
                words={res.words}
                scrollCardIndex={scrollCardIndex}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="sticky-wrapper ">
        <div className="resultPage-btn-wrapper">
          <Button
            endIcon={<ArrowForwardOutlinedIcon />}
            style={{
              width: "100%",
              height: "50px",
              textTransform: "none",
              borderRadius: "12px",
              color: "#fff",
              fontWeight: "400",
              fontSize: "16px",
              fontFamily: "Poppins",
              margin: "2vh 0 0",
              background:
                "linear-gradient(90deg, rgba(45, 167, 255, 0.98) 0%, #FF5EA1 100%)",
              border: "1px solid rgba(64, 64, 64, 0.24)",
            }}
            onClick={handleNavigateToSelectAssessmentLangScreen}
          >
            <img
              src={enSpreadedLetters}
              alt="en-spreaded-letters"
              className="en-spreaded-letters"
            />
            {t("try_another_langauge")}
            <img
              src={hiSpreadedLetters}
              alt="hi-spreaded-letters"
              className="hi-spreaded-letters"
            />
          </Button>
          <Button
            style={{
              height: "49px",
              width: "100%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#E47714",
              fontWeight: "400",
              fontSize: "16px",
              border: "1px solid rgba(228, 119, 20, 1)",
              fontFamily: "Poppins",
              margin: "2vh 0 0",
            }}
            onClick={handleNavigateToInputFieldsScreen}
            startIcon={<KeyboardReturnIcon />}
          >
            {t("assess_new_child")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DemoResultsScreen;
