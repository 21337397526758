import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import "./VopaInputFieldsScreen.css";
import useTranslationSetup from "../../utils/useTranslationSetup";
import useParamValues from "../../utils/useParamValues";
import {
  getClassNumOptions,
  getClassSectionOptions,
  getGenderOptions,
  getGradeLabel,
  getNameLabel,
  getSchoolIdLabel,
  getTeacherLabel,
  getTextFieldType,
} from "../../utils/helper";
import { SchoolIdName } from "../../utils/CommonUtils/constants";
import StudentSelector from "./StudentSelector";

function VopaInputFieldsScreen({
  name,
  setName,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  profession,
  setProfession,
  setShowVopaInputFieldsScreen,
  setShowAssessmentLangScreen,
  setStudentId,
  gender,
  setGender,
  age,
  setAge,
  instructorName,
  setInstructorName,
  phoneNumber,
  setPhoneNumber,
  readingLevel,
  setReadingLevel,
  setRequestBody,
  section,
  setSection,
  schoolShift,
  setSchoolShift,
  fieldsToShow,
  setShowLoadingScreen,
  setCurrentMode,
  setTeacherId,
  schoolData,
}) {
  const { t } = useTranslationSetup();
  const [isDisabled, setIsDisabled] = useState(true);
  const { organisation } = useParamValues();

  const schoolIdNameOptions = SchoolIdName.map((school) => ({
    value: school.schoolId,
    label: `${school.schoolName} (${school.schoolId})`,
  }));

  const handleFieldChange = (value, setFieldValue) => {
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFieldValue(filteredValue);
  };

  const onClickNext = () => {
    let studentPrefix;

    if (profession === "student") {
      studentPrefix = "ST";
    } else if (profession === "teacher") {
      studentPrefix = "TE";
    } else {
      studentPrefix = "OT";
    }
    if (organisation !== "bcg_rajasthan_1")
      setStudentId(`${studentPrefix}D${new Date().getTime()}`);
    const feedbackDetails = {
      name,
      classNum,
      schoolUdise,
      gender,
      age,
      instructorName,
      phoneNumber,
      readingLevel,
    };

    setRequestBody(feedbackDetails);
    setShowVopaInputFieldsScreen(false);
    setShowAssessmentLangScreen(true);
    setCurrentMode("assessment");
  };
  const onClickViewResult = () => {
    setShowVopaInputFieldsScreen(false);
    setShowLoadingScreen(true);
    setCurrentMode("performance");
  };
  useEffect(() => {
    let newIsDisabled = true;
    if (organisation === "pratham_demo_1") {
      newIsDisabled = !(
        name !== "" &&
        classNum !== "" &&
        gender !== "" &&
        age !== "" &&
        instructorName !== "" &&
        phoneNumber !== ""
      );
    } else if (
      organisation === "bihar_demo_1" ||
      organisation === "bihar_pilot_1" ||
      organisation === "bihar_pilot_2"
    ) {
      newIsDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        gender !== ""
      );
    } else if (organisation === "demo" || organisation === "generic_demo") {
      newIsDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    } else if (organisation === "delhi_mcd_1") {
      newIsDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        gender !== "" &&
        section !== "" &&
        instructorName !== "" &&
        schoolShift !== ""
      );
    } else if (organisation === "bcg_rajasthan_1") {
      newIsDisabled = !(gender !== "");
    } else {
      newIsDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    }
    setIsDisabled(newIsDisabled);
  }, [
    organisation,
    name,
    classNum,
    schoolUdise,
    gender,
    age,
    instructorName,
    phoneNumber,
    readingLevel,
    profession,
    section,
    schoolShift,
  ]);

  return (
    <div className="vopa-input-field-screen-container">
      <div className="demo-input-field-heading-wrapper">
        <div className="vopa-input-field-screen-heading-text">
          {t("new_student")}
        </div>
        <div className="vopa-input-field-screen-subheading-text">
          {t("let_us_get_to_know_you_better_before_your_ORF_assessment")}
        </div>
      </div>
      <div className="demo-input-fields-container">
        {fieldsToShow.showInstructorName && (
          <div>
            <div className="input-label">
              {getTeacherLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={instructorName}
              setInputValue={(value) =>
                handleFieldChange(value, setInstructorName)
              }
              autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showPhoneNumber && (
          <div>
            <div className="input-label">
              {t("instructor_phone_number")}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={phoneNumber}
              setInputValue={setPhoneNumber}
              type="number"
            />
          </div>
        )}
        {fieldsToShow.showChildInfo && (
          <div>
            <div className="input-label">
              {getNameLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={name}
              setInputValue={(value) => handleFieldChange(value, setName)}
              // autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showProfession && (
          <div>
            <div className="input-label">
              {t("i_am_a")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={profession}
              setSelectedValue={setProfession}
              options={[
                {
                  value: "student",
                  label: "Student",
                },
                {
                  value: "teacher",
                  label: "Teacher",
                },
                {
                  value: "parent",
                  label: "Parent/Guardian",
                },
              ]}
            />
          </div>
        )}
        {fieldsToShow.showGender && (
          <div>
            <div className="input-label">
              {t("gender")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={gender}
              setSelectedValue={setGender}
              options={getGenderOptions(organisation, t)}
            />
          </div>
        )}
        {fieldsToShow.showGrade && (
          <div>
            <div className="input-label">
              {getGradeLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={classNum}
              setSelectedValue={setClassNum}
              options={getClassNumOptions(organisation)}
            />
          </div>
        )}

        {fieldsToShow.showSection && (
          <div>
            <div className="input-label">
              {t("section")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={section}
              setSelectedValue={setSection}
              options={getClassSectionOptions(organisation)}
            />
          </div>
        )}

        {fieldsToShow.showSchoolId && (
          <div>
            <div className="input-label">
              {getSchoolIdLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            {organisation === "delhi_mcd_1" ? (
              <CustomDropDown
                selectedValue={schoolUdise}
                setSelectedValue={setSchoolUdise}
                options={schoolIdNameOptions}
              />
            ) : (
              <CustomTextField
                inputValue={schoolUdise}
                setInputValue={setSchoolUdise}
                type={getTextFieldType(organisation)}
              />
            )}
          </div>
        )}

        {fieldsToShow.showSchoolShift && (
          <div>
            <div className="input-label">
              {t("school_shift")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={schoolShift}
              setSelectedValue={setSchoolShift}
              options={[
                { value: "Morning (Girls)", label: "Morning (Girls)" },
                { value: "Evening (Boys)", label: "Evening (Boys)" },
                { value: "General (Co-ed)", label: "General (Co-ed)" },
              ]}
            />
          </div>
        )}

        {fieldsToShow.showAge && (
          <div>
            <div className="input-label">
              {t("age")}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={age}
              setInputValue={setAge}
              type="number"
            />
          </div>
        )}

        {fieldsToShow.showReadingLevel && (
          <div>
            <div className="input-label">{t("reading_level")}</div>
            <CustomTextField
              inputValue={readingLevel}
              setInputValue={setReadingLevel}
              autoComplete="on"
            />
          </div>
        )}

        {fieldsToShow.showStudentSelector && (
          <StudentSelector
            data={schoolData}
            setGender={setGender}
            setClassNum={setClassNum}
            setSchoolUdise={setSchoolUdise}
            setStudentId={setStudentId}
            setSection={setSection}
            setTeacherId={setTeacherId}
            setName={setName}
          />
        )}
      </div>
      <div
        className={`sticky-button-container ${organisation === "bcg_rajasthan_1" ? "large" : ""}`}
      >
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          style={{
            height: "46px",
            width: "80%",
            textTransform: "none",
            borderRadius: "12px",
            color: "#FFFFFF",
            fontWeight: "400",
            fontSize: "16px",
            background: isDisabled ? "" : "#EB770C",
            fontFamily: "Poppins",
          }}
          disableElevation
          disabled={isDisabled}
          onClick={onClickNext}
        >
          {t("conduct_assessment")}
        </Button>
        {organisation === "bcg_rajasthan_1" && (
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={{
              height: "46px",
              width: "80%",
              textTransform: "none",
              borderRadius: "12px",
              color: "#FFFFFF",
              fontWeight: "400",
              fontSize: "16px",
              background: isDisabled ? "" : "#EB770C",
              fontFamily: "Poppins",
            }}
            disableElevation
            disabled={isDisabled}
            onClick={onClickViewResult}
          >
            {t("view_results")}
          </Button>
        )}
      </div>
    </div>
  );
}

export default VopaInputFieldsScreen;
