import React, { useState } from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import IconButton from "@mui/material/IconButton";
import CustomCard from "../CustomCard/CustomCard";
import "./CustomExpandableCard.css";

function CustomExpandableCard({ header, wordCount, wordsList }) {
  const [isExpand, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleExpand();
    }
  };

  return (
    <div style={{ paddingTop: "10px", fontFamily: "Open Sans" }}>
      <CustomCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "12px",
            paddingLeft: "19px",
            paddingBottom: "12px",
            paddingRight: "21px",
          }}
          role="button" 
          tabIndex={0} 
          onClick={toggleExpand} 
          onKeyDown={handleKeyPress} 
        >
          <div style={{ paddingTop: "2px" }}>
            {header} - {wordCount}
          </div>
          <div className="expand-up-down">
            <IconButton style={{ padding: "0px" }}>
              {isExpand ? (
                <ArrowDropUpOutlinedIcon />
              ) : (
                <ArrowDropDownOutlinedIcon />
              )}
            </IconButton>
          </div>
        </div>
        {isExpand && (
          <div
            style={{
              marginLeft: "19px",
              marginBottom: wordsList.length > 0 ? "8px" : 0,
            }}
          >
            <div style={{ marginRight: "10px" }}>
              {wordsList.map((word) => (
                <div
                  key={word}
                  style={{
                    display: "inline-block",
                    padding: "2px 6px",
                    background: "#D9D9D9",
                    borderRadius: "2px",
                    lineHeight: "30px",
                    margin: "4px 4px 3px 0px",
                  }}
                >
                  {word}
                </div>
              ))}
            </div>
          </div>
        )}
      </CustomCard>
    </div>
  );
}

export default CustomExpandableCard;
