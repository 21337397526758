// import { useTranslation } from "react-i18next";
// import { useEffect, useState } from "react";

// const useTranslationSetup = () => {
//   const { t, i18n } = useTranslation();
//   const [activeLanguage, setActiveLanguage] = useState(i18n.language);

//   const setLanguage = (langCode) => {
//     localStorage.setItem("app_language", langCode);
//     i18n.changeLanguage(langCode);
//     setActiveLanguage(langCode);
//   };

//   useEffect(() => {
//     const storedLanguage = localStorage.getItem("app_language");
//     if (storedLanguage && storedLanguage !== activeLanguage) {
//       setLanguage(storedLanguage);
//     }
//   },);

//   return { t, activeLanguage, setLanguage };
// };

// export default useTranslationSetup;

import { useTranslation } from "react-i18next";
import { useEffect, useState, useCallback } from "react";

const useTranslationSetup = () => {
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem("app_language") || i18n.language
  );

  const setLanguage = useCallback(
    (langCode) => {
      if (langCode !== activeLanguage) {
        localStorage.setItem("app_language", langCode);
        i18n.changeLanguage(langCode);
        setActiveLanguage(langCode);
      }
    },
    [activeLanguage, i18n]
  );

  // Run only once on mount to sync with localStorage
  useEffect(() => {
    const storedLanguage = localStorage.getItem("app_language");
    if (storedLanguage && storedLanguage !== i18n.language) {
      i18n.changeLanguage(storedLanguage);
    }
    if (!storedLanguage) {
      localStorage.setItem("app_language", activeLanguage);
    }
  }, [i18n, activeLanguage]); // Include i18n in dependencies since we're using its methods

  return { t, activeLanguage, setLanguage };
};

export default useTranslationSetup;
