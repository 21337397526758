import { getMonthFromDate } from "./helper";

const getGroupedAssessmentList = (assessmentList) => {
  const groupedAssessmentList = {};
  assessmentList.forEach((assessment) => {
    const month = getMonthFromDate(assessment.created_at, "long");

    // Check if the month key exists in the groupedData object
    if (Object.prototype.hasOwnProperty.call(groupedAssessmentList, month)) {
      groupedAssessmentList[month].push(assessment);
    } else {
      groupedAssessmentList[month] = [assessment];
    }
  });

  const resultAssessmentList = Object.keys(groupedAssessmentList).map(
    (month) => ({
      month,
      assessments: groupedAssessmentList[month],
    }),
  );

  return resultAssessmentList;
};

export default getGroupedAssessmentList;