import { QueryParams } from "./CommonUtils/constants";

const useParamValues = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const organisation = urlParams.get(QueryParams.ORGNISATION);
  const appLanguage = urlParams.get(QueryParams.APP_LANGUAGE);

  return {
    organisation:
      organisation === "" || organisation === null || organisation === undefined
        ? ""
        : organisation,
    appLanguage:
      appLanguage === "" || appLanguage === null || appLanguage === undefined
        ? "en"
        // : appLanguage,
        : "en",
  };
};

export default useParamValues;
